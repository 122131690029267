import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useButtonStyles = makeStyles(() => ({
  root: {
    color: '#2C3D52 !important',
    fontWeight: 700,
    textTransform: 'unset',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
    }
  },

  contained: {
    backgroundColor: '#2C3D52',
    color: '#FFF !important',
    '&:disabled': {
      border: 'unset !important',
      backgroundColor: '#9B9B9B !important',
    },
    '&:focus': {
      backgroundColor: '#3B5270',
    },
    '&:hover': {
      backgroundColor: '#3B5270',
    },
  },

  outlined: {
    border: '2px solid #2C3D52',
    color: '#2C3D52',
    '&:disabled': {
      border: '2px solid #9B9B9B !important',
      color: '#9B9B9B !important',
    },
    '&:focus': {
      border: '2px solid #3B5270',
      color: '#3B5270',
    },
    '&:hover': {
      border: '2px solid #3B5270',
      color: '#3B5270',
    },
    '&.delete': {
      border: '2px solid #AF3827',
      color: '#AF3827 !important',
    },
  },

  text: {
    backgroundColor: 'unset',
    '&:disabled': {
      color: '#9B9B9B !important',
    },
  }

}));

const Button = ({ children, disabled, onClick, ...rest }: ButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useButtonStyles();

  const handleOnClick: ButtonProps['onClick'] = (event) => {
    if(onClick && !isLoading) {
      setIsLoading(true);
      Promise.resolve(onClick(event)).finally(() => {
        setIsLoading(false);
      });
    }
  };

  return (
    <MuiButton
      classes={classes}
      color={'primary'}
      disabled={isLoading || disabled}
      disableElevation
      disableRipple
      size='large'
      variant='contained'
      {...rest}
      onClick = {handleOnClick}
    >
      {isLoading ? <FormattedMessage id={'waiting'} /> : children}
    </MuiButton>
  );
};

export default Button;
