import type {
  ApiCompleteMessageItem,
  ApiMessageDisplay,
  ApiSummaryInvestment,
  ApiWrapperSummary,
  GenericHttpResult
} from '@agunity/api-v4';
import { apiService } from 'api/apiService';

export const fetchMessages = () =>
  async (): Promise<GenericHttpResult<ApiMessageDisplay[]>> => {
    const response = await apiService.messageV11ListList();
    return response;
  };

export const sendMessage = (subject: string, messageBody: string, toUserIds?: number[], toOrgs?: number[]) =>
  async (): Promise<GenericHttpResult<void>> => {
    const payload: ApiCompleteMessageItem = {
      subject,
      body: messageBody,
      data: []
    };
    if (toOrgs) payload.toOrgs = toOrgs;
    if (toUserIds) payload.toUsers = toUserIds;

    const response = await apiService.messageV10ItemCreate(payload);
    return response;
  };

export const updateState = (id) =>
  async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.messageV10ItemStateCreate(id, 2);
    return response;
  };

export const getWrapperCount = () =>
  async (): Promise<GenericHttpResult<ApiWrapperSummary>> => {
    const response = await apiService.fairtradePlanWrapperV10SummaryCountList();
    return response;
  };

export const updateConsentState = (consentId, state: number,) =>
  async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.consentV10ItemPartialUpdate(consentId, state);
    return response;
  };

export const fetchPlanActiveSummary =
  async (): Promise<GenericHttpResult<ApiSummaryInvestment[]>> => {
    const response = await apiService.planV10ActiveSummaryList();
    return response;
  };