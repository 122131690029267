import React, { ReactElement } from 'react';
import { Modal } from 'react-bootstrap';
import MUIModal from '@material-ui/core/Modal';
import styled from 'styled-components';

import closeIcon from 'assets/icons/close.svg';

const Container = styled.div<any>`
  position: fixed;
  margin-top: 2em;
  width: 834px;
  background-color: white;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 46px;
  border-radius: 5px;
`;

const ImportantText = styled.div`
  font-size: 20px !important;
  line-height: 28px;
`;

type Props = {
  show: boolean;
  handleClose: Function;
  size?: string | any;
  title?: ReactElement | string | null;
  mainTitle?: ReactElement | string | null;
  icon?: ReactElement;
  iconX?: boolean;
  body: ReactElement;
  footer?: ReactElement;
  style?: any;
  bodyStyle?: any;
  titleStyle?: any;
  importantTitle?: boolean;
};

const ModalComponent = ({
  show,
  handleClose,
  size,
  title,
  mainTitle,
  icon,
  iconX,
  body,
  footer,
  style,
  bodyStyle,
  titleStyle,
  importantTitle
}: Props) => {
  return (
    <MUIModal open={show} onClose={handleClose as any} disableBackdropClick={true}>
      <>
        <Container style={style} tabIndex={999}>
          {iconX && (
            <img
              src={closeIcon}
              color='#000'
              style={{
                width: '14px',
                position: 'absolute',
                zIndex: 2,
                right: '24px',
                top: '24px',
                cursor: 'pointer'
              }}
              onClick={handleClose as any}
            />
          )}

          {title && (
            <>
              {/* deleted closeButton from Modal.Header as per the new design */}
              <Modal.Header>
                {importantTitle ?
                  <ImportantText>
                    <strong>
                      {icon}
                      {title}
                    </strong>
                    <br />
                  </ImportantText>
                  :
                  <Modal.Title style={titleStyle || {}}>
                    <strong>
                      {icon}
                      {title}
                    </strong>
                    <br />
                  </Modal.Title>}
              </Modal.Header>
            </>
          )}
          {mainTitle && (
            <>
              {/* deleted closeButton from Modal.Header as per the new design */}
              <Modal.Header>
                <Modal.Title>
                  <strong
                    style={{
                      fontSize: '26px',
                      fontWeight: 500,
                      wordBreak: 'break-word'
                    }}
                  >
                    {mainTitle}
                  </strong>
                  <br />
                </Modal.Title>
              </Modal.Header>
            </>
          )}
          <Modal.Body
            style={
              !bodyStyle
                ? {
                  width: '101.5%',
                  overflowX: 'hidden',
                  overflowY: importantTitle ? 'hidden' : 'auto',
                  maxHeight: '480px'
                }
                : bodyStyle
            }
          >
            {body}
          </Modal.Body>
          {footer && (
            <Modal.Footer
              style={{
                paddingLeft: '2em',
                paddingRight: '2em',
                paddingBottom: '2em'
              }}
            >
              {footer}
            </Modal.Footer>
          )}
        </Container>
      </>
    </MUIModal>
  );
};

export default ModalComponent;
