import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { useState, useContext, ReactElement, useEffect, useMemo } from 'react';
import Button from 'react-bootstrap/Button';

import AdminIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SubMenuIcon from '@material-ui/icons/ArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import InvestmentsIcon from '@material-ui/icons/InsertChartOutlined';
import ReportsIcon from '@material-ui/icons/AssignmentOutlined';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import BookIcon from '@material-ui/icons/LocalLibraryOutlined';
import SurveyIcon from './icons/SurveyIcon';
import OrgDetailsIcon from './icons/OrgDetails';

import { DirtyContext } from 'components/Dirty/Context';
import { noBGList } from './Container';
import { ADD_REMOVE_FLOIDS_PERMISSION, FEATURE_FLAGS, PERMISSIONS } from 'appConstants';
import { report, selectOrganisation } from 'features/SPOReport/redux';
import { selectProfile } from 'features/login/redux';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import * as S from './NavBar.styled';
import Modal from 'components/Modal';
import VersionUI from 'components/shared/VersionUI';
import Backdrop from 'components/Backdrop';

import { LanguageContext } from 'components/Language/Context';
import Logo from 'assets/img/logo_315x80.png';
import { selectFeatureFlags } from 'features/Administration/redux';
import { selectInProgress, selectStatus } from 'api';

function NavBar() {
  const { locale } = useContext(LanguageContext);
  const [open, setOpen] = useState<any>({});
  const history = useHistory();
  const location = useLocation();
  const { isDirty, setDirty, showModal, setShowModal, linkTo, setLinkTo } = useContext(DirtyContext);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const featureFlags = useAppSelector(selectFeatureFlags);
  const organization = useAppSelector(selectOrganisation);
  
  const status = useAppSelector(selectStatus);
  const count = useAppSelector(selectInProgress);
  const isReportResultTab = useMemo(() =>  /\/report\/(approved|past)\/\d+\/results/g.test(location.pathname), [location.pathname]);
  const isLoading = useMemo(() => !!(isReportResultTab && status === 'loading' && count > 0), [count, isReportResultTab, status]);

  const [menuItems, setMenuItems] = useState<any>([]);

  const hasCODImpact = (featureFlags || []).includes(FEATURE_FLAGS[7]);
  const hasOrgConsent = (featureFlags || []).includes(FEATURE_FLAGS[9]);
  useEffect(() => {
    let newMenu = menu.map((item) => {
      if (item?.label === 'DevelopmentPlans') {
        const label = organization._type === 'HL' ? 'PremiumPlans' : item.label;
        return { ...item, label };
      }
      return item;
    });

    const filterSubMenu = (items: any[], labelToRemove: string) =>
      items.map((item) => ({
        ...item,
        subMenu: item.subMenu?.filter(({ label }) => label !== labelToRemove),
      }));

    if (featureFlags.length) {
      if (!hasCODImpact) {
        newMenu = filterSubMenu(newMenu, 'producerData');
      }

      const perms = profile?.permissions || [];
      const isPOProxy = perms.includes(PERMISSIONS.PO_PROXY_READ);
      const hasOrgConsentObject = !!(organization?.consentStates || []).length;
      const hasOrgConsentPerm = isPOProxy || hasOrgConsentObject;
      if (!hasOrgConsent || !hasOrgConsentPerm) {
        newMenu = filterSubMenu(newMenu, 'organizationConsents');
      }
    }

    setMenuItems(newMenu);
  }, [featureFlags.length, hasCODImpact, hasOrgConsent, organization._type, organization?.consentStates, profile]);

  function subActive(link: string, onPage?: string[]): boolean {
    let active = location.pathname === link;
    if (!active && onPage) {
      active =
          onPage.findIndex((p) => new RegExp(p).test(location.pathname)) >= 0;
    }
    return active;
  }

  function doLink(link: string, callback?: Function) {
    if (isDirty) {
      setShowModal(true);
      setLinkTo(link);
      return;
    }
    dispatch(report(null));
    window.localStorage.removeItem('report');
    history.push(link as string);
    callback && callback();
  }

  const title: ReactElement = (
    <span style={{ color:'#000', fontSize: '20px', fontWeight:700, lineHeight:'40px' }}>
      <FormattedMessage id='isDirty' />
    </span>
  );

  const body: ReactElement = (
    <Row>
      <Col style={{ color:'#706D6D', fontSize: '14px',fontWeight:400, marginBottom:'10px' }}>
        <FormattedMessage id='dirtyConfirm' />
      </Col>
    </Row>
  );

  const footer: ReactElement = (
    <Row>
      <Col>
        <Button onClick={() => setShowModal(false)} style={{ color:'#2C3D52', backgroundColor:'white', marginRight:'5px', border:'none', width:'50px', fontSize: '16px',fontWeight:700, lineHeight:'26px' }}>
          <FormattedMessage id='cancel' />
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            setDirty(false);
            setShowModal(false);
            linkTo && history.push(linkTo);
            localStorage.removeItem('selectedWrapperType');
            localStorage.removeItem('selectedwrapperId');
            localStorage.removeItem('selectedWrapperYear');
          }}
          variant='success'
          style={{ color:'white', backgroundColor:'#2C3D52', border:'none', width:'84px', fontSize: '16px', fontWeight:700, padding:'10px 12px', lineHeight:'20px' }}
        >
          <FormattedMessage id='ok' />
        </Button>
      </Col>
    </Row>
  );

  const noBG = noBGList.find((item) =>
    typeof item === 'string'
      ? item === location.pathname
      : item.test(location.pathname)
  );

  return (
    <>
      <Backdrop loading={isLoading} zIndex={99} />
      <S.Container>
        {showModal && (
          <Modal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title={title}
            body={body}
            footer={footer}
            size='lg'
            style={{ width:'640px' }}
          />
        )}
  
        <S.Logo>
          <S.LogoImage src={Logo} data-testid='logo-svg' />
        </S.Logo>
  
        <S.Unorderedlist>
          {menuItems.map(
            ({
              label,
              icon,
              link,
              customMethodLink,
              subMenu,
              isActive,
              testId,
              permission = [],
              permissionNotShow = [],
            }: any) => {
              const perms = profile?.permissions || [];
              // Initially hide menu display that has dependency on perms
              if (!!(!perms.length && permission.length)) {
                return null;
              }
  
              const shouldShowPage = (perms, permission, permissionNotShow) => {
                if (permission.length || permissionNotShow.length) {
                  const showMenu = perms.some((id) => permission.includes(id));
                  const hideMenu = perms.some((id) =>
                    permissionNotShow.includes(id)
                  );
                  return showMenu && !hideMenu;
                }
                return true;
              };
  
              // Hide page if it does not meet the requirements
              if (permission.length || permissionNotShow.length) {
                const shouldDisplay = shouldShowPage(
                  perms,
                  permission,
                  permissionNotShow
                );
                if (!shouldDisplay) return null;
              }
  
              // Hide menu if there's no child menu showing
              if ((subMenu || []).length && !link) {
                const hasActiveChild = subMenu.some((item) => {
                  return shouldShowPage(
                    perms,
                    item.permission || [],
                    item.permissionNotShow || []
                  );
                });
                if (!hasActiveChild) return null;
              }
  
              const active = !!(
                isActive.findIndex((x) => {
                  const pathChecker = new RegExp(`^${x}`, 'g').test(location.pathname);
                  return x === ''
                    ? location.pathname === '/'
                    : pathChecker; // Old Logic = location.pathname.includes(x);
                }) >= 0
              );
              const subOpen = subMenu
                ? subMenu.findIndex(({ link, onPage }: any) =>
                  subActive(link, onPage)
                ) >= 0
                : false;
              const isSubOpen = (subMenu && subOpen) || (subMenu && open[label]);
  
              return (
                <S.List key={label}>
                  <S.TopMenu
                    data-testid={testId}
                    $onPage={active}
                    $active={open[label] || subOpen}
                    $subActive={!subActive}
                    onClick={() => {
                      if (subMenu && !subOpen) {
                        setOpen({ ...open, [label]: !open[label] });
                      } else if (customMethodLink && typeof customMethodLink === 'function') {
                        customMethodLink(locale);
                      } else {
                        doLink(link);
                      }
                    }}
                  >
                    <S.Icon $onPage={active}>{icon}</S.Icon>
                    <S.span $onPage={active}>
                      <FormattedMessage id={label} />
                    </S.span>
                    {subMenu && (
                      <S.Icon $onPage={active}>
                        {!open[label] && !subOpen ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowUpIcon />
                        )}
                      </S.Icon>
                    )}
                  </S.TopMenu>
                  {isSubOpen && subMenu && (
                    <S.SubMenu $active={open[label] || isSubOpen}>
                      {subMenu.map(
                        ({
                          label,
                          subLabel,
                          link,
                          onPage,
                          testId,
                          permission,
                          permissionNotShow,
                          externalLink
                        }: any) => {
                          if (permission && typeof permission === 'number' && !profile?.permissions?.find((id) => permission === id)){
                            return null;
                          }else if (permission && Array.isArray(permission) && !permission.some(perm => (profile.permissions || []).includes(perm))){
                            return null; //for PO-Proxy, permission 56 is used to access manage org, therefore, permission could become an array here
                          } else if (permissionNotShow && permissionNotShow?.length && permissionNotShow.some(perm => (profile.permissions || []).includes(perm))) {
                            return null;
                          }
                          const active = subActive(link, onPage);
                          return (
                            <S.SubMenuItem
                              data-testid={testId}
                              key={label}
                              $onPage={active}
                              onClick={() => {
                                if(externalLink) {
                                  window.open(externalLink, '_blank');
                                  return;
                                }
                                doLink(link as string, () => {
                                  localStorage.removeItem('selectedWrapperType');
                                  localStorage.removeItem('selectedwrapperId');
                                  localStorage.removeItem('selectedWrapperYear');
                                });
                              }}
                            >
                              {subLabel && (
                                <span>
                                  <FormattedMessage id={subLabel} />
                                </span>
                              )}
                              <FormattedMessage id={label} />
                              {active ? (
                                <SubMenuIcon
                                  style={{
                                    color: !noBG ? '#FFF' : '#ececf5',
                                    position: 'absolute',
                                    right: '-1px',
                                    fontSize: '5em',
                                    marginTop: '-20px',
                                    marginRight: '-0.42em'
                                  }}
                                />
                              ) : null}
                            </S.SubMenuItem>
                          );
                        }
                      )}
                    </S.SubMenu>
                  )}
                </S.List>
              );
            }
          )}
        </S.Unorderedlist>
  
        <S.VersionContainer>
          <VersionUI dark />
        </S.VersionContainer>
      </S.Container>
    </>
  );
}

const powerBILink = 'https://app.powerbi.com/groups/me/apps/3eba1d44-49eb-4b9a-9ccb-5df489ec8330/reports/2a8d02df-17a7-4cd1-9ca0-4e3866603e67/ReportSection?ctid=e3f4e868-7745-48bb-9822-86e68836ca2c&experience=power-bi';
const menu = [
  {
    testId: 'home',
    label: 'home',
    link: '/',
    isActive: [''],
    icon: <HomeIcon />
  },
  {
    testId: 'organization-details',
    label: 'OrganizationDetails',
    isActive: [
      '/organizationDetails/geolocationLinks',
      '/organizationDetails/organizationConsents',
      '/organizationDetails/manage/geolocationLinks',
      '/organizationDetails/producerData',
    ],
    permission: [
      PERMISSIONS.FTI_ADMIN_READ,
      PERMISSIONS.FTI_ADMIN_WRITE,
      PERMISSIONS.GEOLINKS,
      PERMISSIONS.PO_WITH_GEOLINK,
      PERMISSIONS.USER_WITH_ORG_GEOLINK,
      PERMISSIONS.PO_USER,
      PERMISSIONS.PO_PROXY_READ,
      PERMISSIONS.CODIMPACT_READ,
      PERMISSIONS.CODIMPACT_CURRENT_ORG_WRITE,
      PERMISSIONS.CODIMPACT_CHILD_ORG_WRITE,
    ],
    icon: <OrgDetailsIcon />,
    subMenu: [
      {
        testId: 'org-details-producer-data',
        label: 'producerData',
        link: '/organizationDetails/producerData',
        onPage: [/^\/organizationDetails\/producerData\/.*$/],
        permission: [
          PERMISSIONS.CODIMPACT_READ,
          PERMISSIONS.CODIMPACT_CURRENT_ORG_WRITE,
          PERMISSIONS.CODIMPACT_CHILD_ORG_WRITE,
        ],
      },
      {
        testId: 'org-details-geolocation-links',
        label: 'GeolocationLinks',
        link: '/organizationDetails/geolocationLinks',
        onPage: [/^\/organizationDetails\/geolocationLinks$/],
        permission: [PERMISSIONS.GEOLINKS, PERMISSIONS.PO_WITH_GEOLINK, PERMISSIONS.USER_WITH_ORG_GEOLINK],
      },
      {
        testId: 'admin-geolocation-links',
        label: 'ManageGeolocationLinks',
        link: '/organizationDetails/manage/geolocationLinks',
        onPage: [/^\/organizationDetails\/manage\/geolocationLinks$/],
        permission: [PERMISSIONS.FTI_ADMIN_READ, PERMISSIONS.FTI_ADMIN_WRITE]
      },
      {
        testId: 'org-details-org-consents',
        label: 'organizationConsents',
        link: '/organizationDetails/organizationConsents',
        onPage: [/^\/organizationDetails\/organizationConsents$/],
        permission: [PERMISSIONS.PO_USER, PERMISSIONS.PO_PROXY_READ],
        permissionNotShow: [
          PERMISSIONS.FIELD_STAFF_VIEW,
          PERMISSIONS.PN,
          PERMISSIONS.FI,
        ],
      },
    ],
  },
  {
    testId: 'investment-projects',
    label: 'DevelopmentPlans',
    icon: <InvestmentsIcon />,
    isActive: [
      '/reportsList',
      '/report/draft',
      '/report/approved',
      '/report/past',
      '/reports/'
    ],
    subMenu: [
      {
        testId: 'draft-plans',
        label: 'draftPlans',
        link: '/reportsList/draft',
        onPage: [
          /\/reportsList\/draft\/(\d+)$/,
          /\/report\/draft\/(\d+)$/,
          /\/reports\/(\d+)\/([a-z]+)/,
          /\/reports\/new\/([a-z]+)/
        ]
      },
      {
        testId: 'approved-plans',
        label: 'approvedPlans',
        link: '/reportsList/approved',
        onPage: [
          /\/reportsList\/approved\/(\d+)\/([a-z]+)$/,
          /\/report\/approved\/(\d+)\/([a-z]+)$/,
          /\/report\/approved\/(\d+)$/,
          /\/report\/approved\/(-\d+)\/([a-z]+)$/
        ]
      },
      {
        testId: 'pastPlans',
        label: 'past-plans',
        link: '/reportsList/past',
        onPage: [
          /\/reportsList\/past\/(\d+)\/([a-z]+)$/,
          /\/report\/past\/(\d+)\/([a-z]+)$/,
          /\/report\/past\/(\d+)$/
        ]
      },
      {
        testId: 'bulkUpload',
        label: 'BulkUpload',
        link: '/reportsList/bulk',
        permission: [PERMISSIONS.CREATE, PERMISSIONS.PROXY_PLANNER],
        onPage: [
          /\/reportsList\/bulk\/(\d+)\/([a-z]+)$/,
          /\/report\/bulk\/(\d+)\/([a-z]+)$/,
          /\/report\/bulk\/(\d+)$/
        ]
      }
    ]
  },
  {
    testId: 'reports-menu',
    label: 'reports',
    link: '/informationHub',
    isActive: [
      '/fieldStaff',
      '/export/commercialProfile',
      '/export/lists',
      '/export/orgLists',
    ],
    icon: <ReportsIcon />,
    permission: [
      PERMISSIONS.FI,
      PERMISSIONS.PN,
      PERMISSIONS.FIELD_STAFF_VIEW,
      PERMISSIONS.READ_EXPORT,
      PERMISSIONS.WRITE_EXPORT,
      PERMISSIONS.SHARE_EXPORT,
      PERMISSIONS.SHARE_ON_BEHALF_EXPORT,
    ],
    subMenu: [
      {
        testId: 'export-commercial-profile',
        label: 'export',
        link: '/export/commercialProfile',
        permission: [
          PERMISSIONS.READ_EXPORT,
          PERMISSIONS.WRITE_EXPORT,
          PERMISSIONS.SHARE_EXPORT,
          PERMISSIONS.SHARE_ON_BEHALF_EXPORT,
        ],
        onPage: [/^\/export\/.*$/],
      },
      {
        testId: 'power-bi-link',
        label: 'dashboards',
        permission: [PERMISSIONS.FI, PERMISSIONS.PN],
        externalLink: powerBILink,
      },
      {
        testId: 'field-stafff',
        label: 'fieldStaffView',
        subLabel: 'beta',
        link: '/fieldStaff',
        onPage: [/\/fieldStaff$/],
        permission: [PERMISSIONS.FIELD_STAFF_VIEW]
      }
    ]
  },
  {
    testId: 'survey-menu',
    label: 'Surveys',
    isActive: ['/latestSurveys', '/surveyList', '/takeSurvey', 'surveyItem'],
    permission: [PERMISSIONS.SURVEY_CREATE, PERMISSIONS.SURVEY_TAKE],
    icon: <SurveyIcon />,
    subMenu: [
      {
        testId: 'survey-take',
        label: 'Take',
        link: '/takeSurvey',
        isActive: ['/takeSurvey'],
        permission: PERMISSIONS.PO_PROXY_READ,
        icon: <FormatListBulletedIcon />
      },
      {
        testId: 'survey-menu',
        label: 'Manage',
        link: '/latestSurveys',
        isActive: ['/latestSurveys'],
        permission: PERMISSIONS.SURVEY_CREATE,
        icon: <FormatListBulletedIcon />
      },
      {
        testId: 'survey-list',
        label: 'MySurveys',
        link: '/surveyList',
        isActive: ['/surveyList'],
        permission: PERMISSIONS.SURVEY_TAKE,
        permissionNotShow: [PERMISSIONS.PO_PROXY_READ],
        icon: <FormatListBulletedIcon />
      }
    ]
  },
  {
    testId: 'informationHub',
    label: 'informationHub',
    customMethodLink: (locale: string) => { window.open(`https://doc.agunity.com/fairinsight-${locale}`, '_blank'); },
    isActive: [], // ['/informationHub']
    icon: <BookIcon />
  },
  {
    testId: 'admin-tab',
    label: 'admin',
    icon: <AdminIcon />,
    isActive: [
      '/manageUsers',
      '/locales',
      '/activityRecords',
      '/manageOrganisations',
    ],
    permission: [PERMISSIONS.ADMIN_LOCALE, PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION],
    subMenu: [
      {
        testId: 'manageUsers-Admin',
        label: 'manageUsers',
        link: '/manageUsers',
        onPage: [/\/manageUsers\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'manageOrgs-Admin',
        label: 'manageOrganisations',
        link: '/manageOrganisations',
        onPage: [/\/manageUsers\/(\d+)$/],
        permission: [PERMISSIONS.PN, PERMISSIONS.FI, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'activityRecords-Admin',
        label: 'activityRecords',
        link: '/activityRecords',
        onPage: [/\/activityRecords\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      },
      {
        testId: 'locale-Admin',
        label: 'localeAdmin',
        link: '/locales',
        onPage: [/\/locales\/(\d+)$/],
        permission: [PERMISSIONS.ADMIN_USERS, ADD_REMOVE_FLOIDS_PERMISSION]
      }
    ]
  }
];

export default NavBar;
