import { createSlice } from '@reduxjs/toolkit';

import { IResponse, IDispatch, IState, CallArgs, Locale } from 'types';
import { RootState } from 'app/store';
import { selectCredentials } from 'features/login/redux';
import { apiUrl } from './environments';

import fetch from 'isomorphic-fetch';

export const hot = '../api/index.ts';

export interface ApiState {
  appUrl?: string;
  endpoints: any;
  status: 'idle' | 'loading' | 'failed';
  error: string;
  inProgress: number;
}

const initialState: ApiState = {
  appUrl: apiUrl,
  endpoints: {},
  status: 'idle',
  error: '',
  inProgress: 0
};

export const counterSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    resetApiState: () => initialState,
    init: (state, action) => {
      // state.endpoints = getApiEndpoints(state.appUrl);
    },
    failure: (state, action) => {
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    },
    inProgress: (state, action) => {
      state.inProgress = action.payload;
    }
  }
});

export const { init, status, failure, inProgress, resetApiState } = counterSlice.actions;

export const selectAppUrl = (state: RootState) => state.api.appUrl;

export const selectStatus = (state: RootState) => state.api.status;

export const selectInProgress = (state: RootState) => state.api.inProgress;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const callEndpoint = ({ noStatus, api, method, body, type = 'json', token, isUnAuthed, noStringify, noContentType }: CallArgs) => async (
  dispatch: IDispatch,
  getState: IState
): Promise<IResponse> => {
  const state = getState();
  const url = selectAppUrl(state);
  const creds = selectCredentials(state);
  if (!isUnAuthed && !creds?.securityToken) {
    return { status: 'error', errors: ['No auth token'] };
  }
  const options: any = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'SecurityToken': creds?.securityToken ? creds.securityToken : null,
      'UserToken': creds?.userToken ? creds?.userToken : null
    },
    body: noStringify ? body : body ? JSON.stringify(body) : null
  };
  if (noContentType) delete options.headers['Content-Type'];
  // console.log('options :::', JSON.stringify({ url, ...options }));
  try {
    if (!noStatus) dispatch(status('loading'));
    // console.log('calling ..... ', `${url}/${api}`);
    dispatch(inProgress(selectInProgress(getState()) + 1));

    const response = await fetch(
      `${url}/${api}`,
      options
    );

    if (response.status === 400) {
      const errors = await response.json();
      // console.log('400 errors ::::', errors);
      let str = '';
      if (errors && errors.errors) {
        for (const [key, value] of Object.entries(errors.errors)) {
          str += key + ' (' + value + ') ';
        }
      }
      if (errors && errors.description) {
        str = errors.description;
      }
      const title = errors?.title ? `${errors?.title}: ` : '';
      const value: IResponse = {
        status: 'error', errors: [title + str], errorCode: errors?.errorCode.toString()
      };

      if (!noStatus) dispatch(status('idle'));
      return value;
    } else if (response.status > 400) {
      // console.log(' > 400 error ::::', response);
      if (!noStatus) dispatch(status('idle'));
      return { status: 'error', errors: ['Server error.'] };
    } else if (response.status === 204) {
      if (!noStatus) dispatch(status('idle'));
      return { status: 'success', data:{}, errors: 'data is empty' };
    }

    let data: any;
    if (type === 'json') {
      data = await response.json();
    }
    else if (type === 'blob') {
      data = await response.blob();
    } else {
      data = await response.text();
    }

    if (!noStatus) dispatch(status('idle'));
    if (data?.errorCode) {
      return { status: 'error', errors: [data.description] , errorCode: data?.errorCode.toString() };
    }

    if (data?.errors && response.status !== 200) {
      console.log(api, 'data?.errors :::::', data?.errors);
      const errors: any[] = [];
      for (const [key, value] of Object.entries(data?.errors)) {
        errors.push(value);
      }
      return { status: 'error', errors };
    }

    const value: IResponse = { status: 'success', data };
    return value;
  } catch (e: any) {
    console.log('callEndpoint error ::::', api, e);
    if (e.message.indexOf('JSON input')) {
      dispatch(status('idle'));
      return { status: 'success', data: {}, errors: ['Server error.'] };
    }
    dispatch(failure(e.message));
    if (!noStatus) dispatch(status('failed'));
    const value: IResponse = { status: 'error', errors: [e.message] };
    return value;
  } finally {
    dispatch(inProgress(selectInProgress(getState()) - 1));
    if (!noStatus && selectInProgress(getState()) === 0) dispatch(status('idle'));
  }
};

export default counterSlice.reducer;
