import { Credentials } from 'types';
import { selectCredentials } from 'features/login/redux';
import type { GenericHttpResult } from '@agunity/api-v4';
import { apiService } from 'api/apiService';

export const setLocale = (locale: string) => async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
  const creds: Credentials = selectCredentials(getState());
  if (!creds?.securityToken) return { data: undefined, headers: {}, status: 200 };
  const response = await apiService.profileV10LocaleCreate(locale);
  return response;
};
