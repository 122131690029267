import { useState } from 'react';

import { DirtyContext } from './Context';

export default function ErrorsProvider({ children }): JSX.Element {
  const [isDirty, setDirty] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [linkTo, setLinkTo] = useState<string>('');
  const [isIntersected, setIsIntersected] = useState<any>({});

  return (
    <DirtyContext.Provider value={{ setDirty: setDirty, isDirty: isDirty, intersected: isIntersected, setIntersected: setIsIntersected, showModal: showModal, setShowModal: setShowModal, linkTo: linkTo, setLinkTo: setLinkTo }}>
      {children}
    </DirtyContext.Provider>
  );
}
