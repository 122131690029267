import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import RadioButtonUncheckedIcon from '../../../assets/icons/radioButton.svg';
// import RadioButtonCheckedIcon from '../../../assets/icons/radioButtonCheck.svg';
// import RadioButtonDisabledIcon from '../../../assets/icons/radioButtonDisabledIcon.svg';
import { FormattedMessage } from 'react-intl';
import { Fragment, memo } from 'react';
import { SURVEY_STATUS } from '../../../appConstants';
import { Radio } from '@material-ui/core';

const SurveyFrequency = memo(
  ({
    status,
    error,
    frequency,
    setFrequency,
  }: {
    status: number;
    error?: boolean;
    frequency: number;
    setFrequency: (frequency: number) => void;
  }) => {
    const renderCheckBox = (frequencyNumber: number) => {
      return (
        <Radio // Checkbox
          data-testid={`survey-frequency-${frequencyNumber}`}
          checked={frequency === frequencyNumber}
          disabled={status >= SURVEY_STATUS.Closed}
          onClick={() => setFrequency(frequencyNumber)}
          color='default'
          style={{ color: ((error) ? 'var(--error-color)' : ((frequency === frequencyNumber) ? 'var(--color-link-blue)' : '')) }}
          // icon={<img src={RadioButtonUncheckedIcon} alt='' />}
          // checkedIcon={
          //   <img
          //     src={
          //       status >= SURVEY_STATUS.Closed
          //         ? RadioButtonDisabledIcon
          //         : RadioButtonCheckedIcon
          //     }
          //     alt=''
          //   />
          // }
        />
      );
    };

    return (
      <Fragment>
        <div style={{ fontWeight: 600, fontSize: '0.8em', marginBottom: '12px' }}>
          <FormattedMessage id='SurveyFrequencyTitle' />
        </div>
        {error && (
          <small className='d-block' style={{ color: 'var(--error-color)', margin: '0.5rem 0' }}>
            <FormattedMessage id='fieldMandatory' />
          </small>
        )}
        <div style={{ display: 'flex', gap: '42px', marginBottom: '20px' }}>
          <FormControlLabel
            control={renderCheckBox(1)}
            label={<FormattedMessage id='EveryWeek' />}
            style={{ height: '18px', color: ((error) ? 'var(--error-color)' : '#706D6D') }}
          />
          <FormControlLabel
            control={renderCheckBox(2)}
            label={<FormattedMessage id='EveryTwoWeek' />}
            style={{ height: '18px', color: ((error) ? 'var(--error-color)' : '#706D6D') }}
          />
          <FormControlLabel
            control={renderCheckBox(4)}
            label={<FormattedMessage id='EveryFourWeek' />}
            style={{ height: '18px', color: ((error) ? 'var(--error-color)' : '#706D6D') }}
          />
        </div>
      </Fragment>
    );
  }
);

export default SurveyFrequency;
