import styled from 'styled-components';
import '@fontsource/roboto';
import '@fontsource/exo';
import Color from 'color';

export const ChooseFile = styled.label`
  margin: 10px;
  padding: 0px 30px;
  line-height: 33px;
  text-align: center;
  border-radius: 3px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2C3D52;
  border: 2px solid #2C3D52;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const HeadContainer = styled.div`
  width: 100%;
  padding: 1.2em;
  margin-left: 20px;
  margin-bottom: -20px;
`;

export const space = styled.div`
  width: 100%;
  height: 1em;
  background: #f6f7f7;
`;

export const Header = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
  word-break: break-all;
  cursor: pointer;
  margin-bottom: 1em;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: -1.5em;
  position: relative;
  padding: 1em;
`;
export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin-left: 0.6em;
  margin-top: 1.5em;
`;
export const Sortable = styled.th`
  cursor: pointer;
`;
export const BadStatus = styled.div`
  width: 100%;
  padding: 4em;
  text-align: center;
  font-size: 1.2em;
`;
export const ReportDetails = styled.div`
  width: 100%;
`;
export const SVG = styled.img`
  width: fit - content;
  margin-right: 0.5em;
`;
export const Icon = styled.div<any>`
  height: 32px;
  float: right;
  width: fit-content;
`;

export const TD = styled.td`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #1f2b3d;
  float: left;
  line-height: 32px;
`;

export const TableDetailRow = styled.tr`
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  background: #fdfdfd;
  color: #706d6d;
  border-top: 0.5px solid #1f2b3d;
  border-bottom: 0.5px solid #1f2b3d;
  min-height: 32px;
  line-height: 32px;
`;

export const MenuItem = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: #9b9b9b;
  padding: 0.5em 1em;
  cursor: pointer;
  &:hover {
    background: #f6f7f7;
  }
`;

export const NavContainer = styled.div`
  width: auto;
  padding: 1em;
  text-align: center;
  font-size: 0.9em;
  margin: auto;
`;
export const Arrow = styled.span<{ $active: boolean }>`
  display: inline-block;
  position: relative;
  top: -1px;
  ${(props) => (props.$active ? 'cursor: pointer;' : '')}
`;
export const Nav = styled.span<{ $active: boolean }>`
  display: inline-block;
  padding: 0.5em;
  font-weight: bold;
  color: ${(props) =>
    props.$active ? '#706D6D' : Color('#706D6D').lighten(0.5).toString()};
  ${(props) => (!props.$active ? 'cursor: pointer;' : '')}
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Column = styled.div`
  width: 50%;
  padding: 0 1em 0 0;
  position: relative;

  & textarea {
    min-height: 6em;
  }
`;
export const Column2 = styled.div`
  width: 100%;
  padding: 0 1em 0 0;
  position: relative;

  & textarea {
    min-height: 6em;
  }
`;

export const Dates = styled.div`
  width: 100%;
  display: flex;
`;

export const Date = styled.div`
  width: 12em;
  padding-right: 1em;
`;

export const SpaceBetween = styled.div`
  width: 100%;
  position: relative;
`;

export const Error = styled.small`
  color: var(--error-color);
  position: absolute;
  right: 32px;
  top: 32px;
`;

export const Label = styled.small`
  font-weight: 600;
  font-size: 0.8em;
  color: #000000;
`;

export const Description = styled.div`
  font-weight: normal;
  font-size: 0.8em;
  color: #5c5c5c;
  margin-top: 5px;
  margin-bottom: 1.5em;
`;
export const btn = styled.button`
  box-sizing: border-box;
  position: absolute;
  width: 100px;
  height: 30px;
  border: 1px solid #bec7d8;
  border-radius: 3px;
  right: 2px;
  background-color: white;
  margin-top: -8px;
`;

export const btnText = styled.p`
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #2c3d52;
  margin-left: -30px;
`;

export const svg = styled.div`
  margin-left: -1.5em;
  margin-right: 1em;
  margin-top: -1px;
`;

export const Loading = styled.p`
  margin: 170px auto;
  width: 370px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  text-align: center;
  color: #000;
`;

export const List = styled.ul`
  margin: 10px auto;
  padding: 0;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  line-height: 18.75px;
  text-align: center;
  color: #000;
`;

export const ListText = styled.li`
  list-style-position: inside;

  &:first-child {
    list-style-type: none;
  }
`;

export const ModalBody = styled.div``;

export const ModalBodyText = styled.div`
  margin: 0;
  color: #706D6D;
`;

export const ModalBodyForm = styled.div`
  .form-button {
    width: fit-content;
    margin: 0 20px 0 0;
    padding: 7px 25px;
    text-transform: initial;
    color: #2C3D52;
    border-color: #2C3D52 !important;
  }

  .save-button {
    margin: 0;
    color: #FFF !important;
    background-color: #2C3D52;

    &:hover {
      background-color: #2C3D52;
    }
  }
`;

export const FormActionWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 2.5em 0 0 0;
`;