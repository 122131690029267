import { useEffect, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PrintContext } from 'components/Printing/Context';

export function useWithPrint(): { isPrinting: boolean } {
  const location = useLocation();
  const { setPrinting, isPrinting } = useContext(PrintContext);

  useEffect(() => {
    const isPrinter = location.pathname.includes('print');
    setPrinting(isPrinter);
  }, [location, setPrinting]);

  return { isPrinting };
}

export const useSortedData = (data, sort) => {
  const sortedData = useMemo(() => {
    const field = sort.key || sort.field;
    // If the field is empty, return the unsorted data
    if (!field) return data;

    const compareValues = (aValue, bValue) => {
      if (aValue === '' || bValue === '') {
        if (aValue === '') return -1;
        if (bValue === '') return 1;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.trim().localeCompare(bValue.trim(), undefined, { sensitivity: 'base' });
      }
      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return aValue - bValue;
      }
      return 0;
    };

    // Sort the data based on the field and isAsc flag
    return [...data].sort((a, b) => {
      const aValue = a[field] ?? '';
      const bValue = b[field] ?? '';
      const result = compareValues(aValue, bValue);
      return sort.isAsc ? result : -result;
    });
  }, [data, sort]);

  return sortedData;
};

export const useFilteredData = (
  data: { [key: string]: any }[],
  filters: { [key: string]: { [key: string]: boolean } }
) => {
  return useMemo(() => {
    if (!data.length && !Object.keys(filters).length) return [];

    if (Object.values(filters).every((val) => JSON.stringify(val) === '{}')) {
      return data;
    }

    return data.filter((item) => {
      return Object.entries(filters).every(([filterKey, filterCriteria]) => {
        // Get active filters for this category
        const activeFilterKeys = Object.keys(filterCriteria).filter(
          (key) => filterCriteria[key]
        );

        // If no active filters, consider this filter category as passed
        if (!activeFilterKeys.length) return true;

        // Check if item matches at least one of the active filter criteria
        return activeFilterKeys.some(
          (key) => item[filterKey]?.toString() === key
        );
      });
    });
  }, [data, filters]);
};

export const useSearchData = (data, search: { [key: string]: string }) => {
  return useMemo(() => {
    // If data is empty or search object is empty, return all data
    if (!data.length || !Object.keys(search).length) return data;

    // Filter data based on search criteria
    return data.filter((item) => {
      return Object.keys(search).every((key) => {
        const searchValue = search[key];
        // Check if search value is empty, undefined, or null
        if (!searchValue) {
          return true;
        }
        // Otherwise, perform the search
        return item[key]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    });
  }, [data, search]);
};
