import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import { updateCssVars } from 'components/Theme/Provider';
import * as S from './Container.styled';
import theme from 'components/Theme/themes/theme.json';
import { PERMISSIONS } from 'appConstants';
import { useAppSelector } from 'app/hooks';
import { selectProfile } from 'features/login/redux';

export const noBGList = [
  '/',
  /(\/profile)/,
  /(\/reports\/[0-9])/,
  /(\/reports\/new)/,
  /(\/report\/draft\/[0-9])/,
];

function Container({ children, showFullWidth }) {
  const location = useLocation();
  const perms = useAppSelector(selectProfile)?.permissions || [];
  const isPOProxy = perms.includes(PERMISSIONS.PO_PROXY_READ);

  const noBG = noBGList.some((item) =>
    typeof item === 'string'
      ? item === location.pathname
      : item.test(location.pathname)
  );

  const notPadded = useMemo(() => {
    const notPaddedList: RegExp[] = [
      /^\/organizationDetails\/producerData(?:\/\d+)*$/,
      /^\/export\/.*$/,
      /^\/locales$/,
    ];
    if (isPOProxy) {
      notPaddedList.push(/^\/organizationDetails\/organizationConsents$/);
    }
    return notPaddedList.some((pattern) => pattern.test(location.pathname));
  }, [isPOProxy, location.pathname]);

  useEffect(() => {
    updateCssVars(noBG ? theme.AltBG : theme.BG);
  }, [noBG]);

  return (
    <S.Outer $notPadded={notPadded} $fullWidth={showFullWidth}>
      <S.Container $noBG={noBG}>{children}</S.Container>
    </S.Outer>
  );
}

export default Container;
