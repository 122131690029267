import { useState } from 'react';

import { PrintContext } from './Context';

export default function ErrorsProvider({ children }): JSX.Element {
  const [isPrinting, setIsPrinting] = useState<boolean>(false);

  return (
    <PrintContext.Provider value={{ setPrinting: setIsPrinting, isPrinting: isPrinting }}>
      {children}
    </PrintContext.Provider>
  );
}
