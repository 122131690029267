import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;
  padding: 1em;
  position: relative;
  overflow: hidden;
`;
export const btn = styled.button`
  box-sizing: border-box;
  position: absolute;
  width: 90px;
  height: 30px;
  border: 1px solid #bec7d8;
  border-radius: 3px;
  right: 3.5em;
  background-color: white;
  margin-top: -8px;
`;

export const btnText = styled.p`
  font-family: 'Exo';
  font-style: normal;
  font-weight: 600;
  font-family: Roboto;
  font-size: 14px;
  line-height: 30px;
  color: #2c3d52;
  margin-left: -25px;
`;

export const SVG = styled.img`
  width: 30px;
  height: 14px;
  margin-top: 0.5em;
  margin-left: 50px;
  color: #2c3d52;
  border-radius: 4px;
  position: absolute;
  left: 10px;
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  border: 1px solid #BEC7D8;
  border-radius: 3px;
  height: 32px;
  width: 272px;
`;

export const SearchBarInput = styled.input`
  outline: none;
  font-size: 14px;
  line-height: 18px;
  border: none;
  color: #706D6D;
  width: 224px;
  height: 18px;
`;

export const SearchBarIcon = styled.img`
  cursor: pointer;
`;

export const SurveyHeader = styled.div`
  width: 100%;
  text-align: center;
  background-color: #E0EEFF;
  height: 48px;
  line-height: 48px;
  font-size: 16px;
  font-family: Roboto,serif;
  font-weight: 500;
`;

export const NoSurveyInstances = styled.p`
  margin: 20px auto;
  width: 370px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: #706d6d;
  text-align: center;
`;

export const NoData = styled.p`
  margin: 20px auto;
  width: 370px;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
  color: #000;
  text-align: center;
`;

export const SurveyCompleteModalStyle = { padding: '48px', boxSizing: 'border-box', width: '620px', height: 'auto' };
export const SurveyCompleteModalTitleStyle = { lineHeight: '12px' };
export const SurveyCompleteModalStyle_CheckboxContainer = { display: 'flex', gap: '8px', alignItems: 'start', marginBottom: '48px' };
export const SurveyCompleteModalStyle_ExplainText = { marginBottom: '24px' };
export const SurveyCompleteModalStyle_CheckboxInput = { height: '18px', width: '18px' };
export const SurveyCompleteModalStyle_CheckboxLabel = { fontSize: '14px', margin: 0, lineHeight: '20px' };
export const SurveyCompleteModalStyle_ButtonContainer = { display: 'flex', justifyContent: 'end', gap: '16px' };
export const SurveyCompleteModalStyle_Button_CloseModal = { width: '230px', height: '44px', fontWeight: 700, fontSize: '16px', color: '#2C3D52', border: 'none', backgroundColor: 'transparent' };
export const SurveyCompleteModalStyle_Button_SubmitResult = { width: '207px', height: '44px', borderRadius: '4px', fontWeight: 700, fontSize: '16px', color: 'white', border: 'none' };
export const SurveySaveExit_Container = { cursor: 'pointer', display: 'flex', gap: '10px', alignItems: 'center' };
export const SurveySaveExit_Text = { fontWeight: 700, fontSize: '16px', lineHeight: '20px', color: '#2C3D52' };
export const SurveyInstanceColumn: CSSProperties = { textAlign: 'left', fontSize: '14px', color: '#706D6D', lineHeight: '18px', fontStyle: 'normal', fontWeight: 'normal' };
export const SurveyCompleteContainer = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30em' };
export const SurveyCompleteButton = { backgroundColor: '#2c3d52', borderRadius: '4px', color: 'white', border: 0, cursor: 'pointer', padding: '10px 32px' };
