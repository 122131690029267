import styled from 'styled-components';
import Color from 'color';
import { createGlobalStyle } from 'styled-components';
import { whichBrowser } from 'utils';

export const TextA = '#568F99';
export const TextB = '#39756A';

export const LinkColor = 'blue';

export const ButtonColor = Color('#98995f');

export const DarkBGColor = Color('black');

export const BGColorDark = Color('grey').darken(0.4).toString();

export const SocialIconColor = Color('gold').darken(0.2).toString();

export const TextAL = Color(TextA).lighten(0.6).toString();

export const Padded = styled.span`
  display: inline-block;
  padding: 1em;
`;

export const MT1 = styled.div`
  margin-top: 1em;
`;

export const FigmaStyle = createGlobalStyle`
  html {
    --color-text-B: #39756A;
		--color-text-A: #568F99;
		--color-light-border: rgba(86, 143, 153, 0.6);
		--color-social-icon: rgba(86, 143, 153, 0.6);
		--color-button-active: rgba(86, 143, 153, 1);
		--color-button: #00B1E2;
		--color-text-al: rgba(173, 204, 209, 1);
    --color-light-gray: #706D6D;
    --color-link-blue: #3284FB;
    --color-link-hover-blue: #255EC4;
  }
`;

export const Header1 = styled.div`
  font-size: 1em;
`;

export const Header2 = styled.div`
  font-size: 0.9em;
  padding: 0.5em 0;
  color: #777777;
`;

export const Header = styled.h1`
  width: 100%;
  padding: 1em 0 0.5em 32px;
  font-size: 26px;
  font-weight: bold;
`;

export const Table = styled.div`
  width: auto;
  padding: 0 1em;
  table {
    width: 100%;
  }
  thead > tr {
    background-color: #f6f7f7;
    th {
      white-space: nowrap;
      padding: 0.75em 0 0.75em 0.75em;
      color: #777777;
      font-size: 0.9em;
      :first-of-type {
        padding-left: 1.5em;
      }
      :last-of-type {
        padding-right: 1.5em;
        width: 2em;
      }
    }
  }
`;

export const TableRow = styled.tr<{ $isActive?: boolean }>`
  border-bottom: ${(props) =>
    props.$isActive
      ? ''
      : whichBrowser() !== 'chrome'
        ? '2px solid #E8E8E8'
        : '1px solid #E8E8E8'};
  cursor: pointer;
  td {
    padding: 1em 0 0.75em 1em;
    color: #706d6d;
    font-size: 0.8em;
    // word-break: break-all;
    word-wrap: break-word;

    :first-of-type {
      padding-left: 1.5em;
    }
    :last-of-type {
      padding-right: 1.5em;
    }
  }
`;

export const TableDetailRow = styled.tr`
  border-bottom: 1px solid #e8e8e8;
  td {
    padding: 0 2em 0 2em;
  }
`;

export const Sortable = styled.th`
  cursor: pointer;
`;

export const BaseButton = styled.button`
  padding: 0.5em 0;
  background: none;
  border: none;
  font-size: small;

  &:hover {
    color: #000000;
  }
`;

export const GrayText = styled.span<{ $cursor?: string }>`
  color: #5c5c5c;
  cursor: ${(props) => props.$cursor || 'default'};
`;

export const ImageDropContainer = styled.div<any>`
  border: 1px dashed var(--color-light-gray);
  border-radius: 3px;
  display: grid;
  align-content: center;
  justify-content: center;
  min-height: 100px;
  max-height: 5vh;

  label,
  button {
    cursor: pointer;
    color: var(--color-link-blue);
    text-decoration: underline;
    &:hover {
      color: var(--color-link-hover-blue);
    }
  }
`;

export const ImageConstraint = styled.p`
  font-size: 0.9em;
`;

export const CoverPhotoName = styled(GrayText)`
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`;
