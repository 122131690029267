import { useState, useReducer } from 'react';
import * as S from '../styled';
import { useIntl, FormattedMessage } from 'react-intl';
import type { FormDataType, FormTypeWithAction } from '../types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { submitFeedback, submitNoFeedback } from '../redux';
import { useToast } from 'features/SPOReport/hooks';
import { selectProfile } from 'features/login/redux';

import Modal from '../../../components/Modal';
import { Button } from '@mui/material';
import { Textarea } from '../styled';

import mood0 from 'assets/img/mood-0.svg';
import mood1 from 'assets/img/mood-1.svg';
import mood2 from 'assets/img/mood-2.svg';
import mood3 from 'assets/img/mood-3.svg';

const validateData = (value: any, validation: any[]): string | boolean => {
  const error: string[] = [];
  validation.every((item: any) => {
    if (item.rule.test(value)) {
      error.push(item.message);
      return false;
    } else {
      return true;
    }
  });
  return error.join(', ') || false;
};

const FeedbackModal = ({ show = false, onClose }: FormTypeWithAction) => {
  const { formatMessage } = useIntl();
  const { toast } = useToast();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);

  const [loading, setLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  
  const [formData, setFormData] = useReducer<(state: FormDataType, payload: React.ChangeEvent<HTMLTextAreaElement> | { target: { name: string, value: any }; }) => FormDataType>((state, payload) => {
    const newState = { ...state };
    if (!loading) {
      const { target } = payload;
      newState.data[target.name] = { ...newState.data[target.name], value: target.value, error: ((newState.data[target.name].rules) ? validateData(target.value, newState.data[target.name].rules) : false) };
      newState.status = Object.values(newState.data).every(({ value }) => (!/^$|^(null|undefined)$/g.test(String(value).trim())));
    }
    return newState;
  }, {
    status: false,
    data: {
      satisfactionLevel: {
        value: null,
        rules: [
          { rule: /^null$/g, message: formatMessage({ id: 'required' }) }
        ]
      },
      generalComment: {
        value: '',
        rules: [
          { rule: /^$/g, message: formatMessage({ id: 'required' }) }
        ]
      },
      improvement: {
        value: '',
        rules: [
          { rule: /^$/g, message: formatMessage({ id: 'required' }) }
        ]
      }
    }
  });

  const handleClose = () => {
    if (!loading && !profile.isNeedFeedback) {
      onClose();
    }
  };

  const sendFeedback = async () => {
    if (formData.status) {
      try {
        const { satisfactionLevel, generalComment, improvement } = formData.data;
        const data = { satisfactionLevel: satisfactionLevel.value, generalComment: generalComment.value, improvement: improvement.value };
        setLoading(true);
        const response = await dispatch(submitFeedback(data));
        if (response.status === 200) {
          setLoading(false);
          toast('weReceivedYourFeedback', 'success');
          onClose();
        }
      } catch (error: any) {
        setLoading(false);
        toast('error', 'error', { error: error.data.description });
      }
    }
  };

  const sendNoFeedback = () => {
    try {
      dispatch(submitNoFeedback());
      onClose();
    } catch (error: any) {
      setLoading(false);
      toast('error', 'error', { error: error.data.description });
    }
  };

  const handleAskMeAgainLater = () => {
    setShowConfirmModal(true);
  };

  const handleCancel = () => {
    if (profile.isNeedFeedback) {
      handleAskMeAgainLater();
    } else {
      handleClose();
    }
  };

  const handleConfirm = () => {
    sendNoFeedback();
    setShowConfirmModal(false);
  };
  
  return (
    <>
      <Modal
        show={show}
        handleClose={handleClose}
        iconX={!loading && !profile.isNeedFeedback}
        mainTitle={<FormattedMessage id='feedback' />}
        body={
          <S.ModalBody>
            <S.ModalBodyText style={{ marginBottom: '1em' }}>
              <FormattedMessage id='tellUsAboutYourExperience' />
            </S.ModalBodyText>
            <S.FormInputLabel style={{ marginBottom: '1em' }}>
              <FormattedMessage id='howSatisfiedAreYou' />
            </S.FormInputLabel>
            <div className='mood-wrapper d-flex justify-content-between'>
              <S.Image className={['mood', 'mood-0', ((formData.data.satisfactionLevel.value === 0) ? 'active' : ''), ((loading) ? 'disabled' : '')].filter((className) => (!!className)).join(' ')} src={mood0} onClick={() => { setFormData({ target: { name: 'satisfactionLevel', value: 0 } }); }} />
              <S.Image className={['mood', 'mood-1', ((formData.data.satisfactionLevel.value === 1) ? 'active' : ''), ((loading) ? 'disabled' : '')].filter((className) => (!!className)).join(' ')} src={mood1} onClick={() => { setFormData({ target: { name: 'satisfactionLevel', value: 1 } }); }} />
              <S.Image className={['mood', 'mood-2', ((formData.data.satisfactionLevel.value === 2) ? 'active' : ''), ((loading) ? 'disabled' : '')].filter((className) => (!!className)).join(' ')} src={mood2} onClick={() => { setFormData({ target: { name: 'satisfactionLevel', value: 2 } }); }} />
              <S.Image className={['mood', 'mood-3', ((formData.data.satisfactionLevel.value === 3) ? 'active' : ''), ((loading) ? 'disabled' : '')].filter((className) => (!!className)).join(' ')} src={mood3} onClick={() => { setFormData({ target: { name: 'satisfactionLevel', value: 3 } }); }} />
            </div>
            <div className='mood-text-wrapper d-flex justify-content-between' style={{ marginTop: '0.5em' }}>
              <S.ModalBodyText>
                <FormattedMessage id='verySatisfied' />
              </S.ModalBodyText>
              <S.ModalBodyText>
                <FormattedMessage id='veryDissatisfied' />
              </S.ModalBodyText>
            </div>
            <S.ModalBodyForm>
              <S.FormInputWrapper>
                <S.FormInputLabel>
                  <FormattedMessage id='feedbackLabel1' />
                </S.FormInputLabel>
                <Textarea data-testid='feedback-form-input-1' name='generalComment' placeholder={formatMessage({ id: 'feedbackInputMaxCharacters' })} maxLength={200} rows={5} value={formData.data.generalComment.value} disabled={loading} onChange={setFormData} />
              </S.FormInputWrapper>
              <S.FormInputWrapper>
                <S.FormInputLabel>
                  <FormattedMessage id='feedbackLabel2' />
                </S.FormInputLabel>
                <Textarea data-testid='feedback-form-input-2' name='improvement' placeholder={formatMessage({ id: 'feedbackInputMaxCharacters' })} maxLength={200} rows={5} value={formData.data.improvement.value} disabled={loading} onChange={setFormData} />
              </S.FormInputWrapper>
              <S.FormActionWrapper>
                <Button data-testid='feedback-form-cancel-button' className='form-button' variant='text' disabled={loading} onClick={handleCancel}>
                  <FormattedMessage id={(profile.isNeedFeedback) ? 'askMeAgainLater' : 'cancel'} />
                </Button>
                <Button data-testid='feedback-form-add-links-button' className='form-button save-button' variant='contained' disabled={!formData.status || loading} onClick={sendFeedback}>
                  <FormattedMessage id={(loading) ? 'waiting' : 'submit'} />
                </Button>
              </S.FormActionWrapper>
            </S.ModalBodyForm>
          </S.ModalBody>
        }
        bodyStyle={{ left: 0, marginRight: '-1em', height: '20px !important' }}
        style={{ width: '640px' }}
      />

      <Modal // Confirm Modal
        show={showConfirmModal}
        handleClose={() => {}}
        mainTitle={<FormattedMessage data-testid='feedback-form-confirm-title' id='yourFeedbackMatters' />}
        body={
          <S.ModalBody>
            <S.ModalBodyText style={{ marginBottom: '1em' }}>
              <FormattedMessage id='yourFeedbackMattersText1' />
            </S.ModalBodyText>
            <S.ModalBodyText>
              <FormattedMessage id='yourFeedbackMattersText2' />
            </S.ModalBodyText>
            <S.ModalBodyForm>
              <S.FormActionWrapper>
                <Button data-testid='feedback-form-back-button' className='form-button' variant='text' onClick={() => { setShowConfirmModal(false); }}>
                  <FormattedMessage id='back' />
                </Button>
                <Button data-testid='feedback-form-confirm-button' className='form-button save-button' variant='contained' onClick={handleConfirm}>
                  <FormattedMessage id='accessFairInsight' />
                </Button>
              </S.FormActionWrapper>
            </S.ModalBodyForm>
          </S.ModalBody>
        }
        bodyStyle={{ left: 0, marginRight: '-1em' }}
        style={{ width: '640px' }}
      />
    </>
  );
};

export default FeedbackModal;