import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { ReactElement, useContext } from 'react';

import * as S from './ConfirmModal.styled';
import { Col, Row } from 'react-bootstrap';
import { LanguageContext } from '../Language/Context';
import ModalNotification, {
  NotifyUser
} from '../ModalNotification/ModalNotification';
import { StringLiteralLike } from 'typescript';

type Props = {
  icon?: any;
  show: boolean;
  handleSuccess?: any;
  handleClose?: any;
  setShow: Function;
  successMessageId?: string;
  successMessageValues?: any;
  successMessageId2?: string;
  successMessageId3?: string;
  successMessageId4?: string;
  successMessageTop?: string;
  successTitle?: string;
  successTitleValues?: any;
  footer?: ReactElement | null;
  body?: ReactElement;
  notifyUser?: null | NotifyUser;
  from?: string;
  style?: any;
  noIconX?: boolean;
  modalTestId?: string;
  testId?: string;
};

function SuccessModal({
  icon,
  show,
  handleSuccess,
  setShow,
  successMessageId,
  successMessageValues,
  successMessageId2,
  successMessageId3,
  successMessageId4,
  successTitle,
  successTitleValues,
  successMessageTop,
  footer,
  handleClose,
  body,
  notifyUser,
  from,
  style,
  noIconX,
  modalTestId,
  testId = 'success-confirm-modal',
}: Props) {
  const { messages } = useContext(LanguageContext);
  if (!show) return null;

  return (
    <>
      <S.ConfirmDrop />
      <S.ConfirmDialog style={style} data-testid='success-confirm-modal'>
        {handleClose && !noIconX && (
          <FontAwesomeIcon
            icon={faTimes}
            color='grey'
            style={{
              fontSize: '1em',
              position: 'absolute',
              right: '1em',
              top: '1em',
              cursor: 'pointer'
            }}
            onClick={handleClose as any}
          />
        )}
        {successMessageTop && (
          <S.Body style={{ marginBottom: '-2em', marginTop: '-0.5em' }}>
            <FormattedMessage id={successMessageTop} />
          </S.Body>
        )}
        {successTitle && (
          // it's possible to show failure title, so modalTestId can overwrite data-testid here
          <S.Title data-testid={modalTestId ?? 'modal-title'}>
            <strong>
              <FormattedMessage
                id={successTitle}
                values={{
                  ...successTitleValues,
                  label: from === 'past' ? 'investments' : 'plans'
                }}
              />
            </strong>
          </S.Title>
        )}
        {!handleClose && !noIconX && (
          <FontAwesomeIcon
            icon={faTimes}
            color='grey'
            style={{
              fontSize: '1em',
              position: 'absolute',
              right: '1em',
              top: '1em',
              cursor: 'pointer'
            }}
            onClick={handleSuccess ? handleSuccess : () => setShow(false)}
          />
        )}
        <S.Body2>
          {icon && icon}
          <br />
          {successMessageId && (
            <p>
              <FormattedMessage
                id={successMessageId}
                values={successMessageValues}
              />
            </p>
          )}
          {notifyUser && (
            <Row>
              <Col md={12} className='row justify-content-center'>
                <ModalNotification notifyUser={notifyUser} />
              </Col>
            </Row>
          )}
          {successMessageId2 && (
            <p>
              <FormattedMessage id={successMessageId2} />
            </p>
          )}
          {successMessageId3 && (
            <p>
              <FormattedMessage id={successMessageId3} />
            </p>
          )}
          {successMessageId4 && (
            <p>
              <FormattedMessage id={successMessageId4} />
            </p>
          )}
        </S.Body2>
        {body && (
          <S.Body style={{ marginTop: '-2.5em' }}>
            <S.ConfirmBody>{body}</S.ConfirmBody>
          </S.Body>
        )}
        {footer}
      </S.ConfirmDialog>
    </>
  );
}

export default SuccessModal;
