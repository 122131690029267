import { useContext } from 'react';
import { LanguageContext } from '../Language/Context';
import styled from 'styled-components';

export const Notification = styled.p`
  border-radius: 3px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  padding: 0.2em 1em;
`;

export interface NotifyUser {
  show: boolean;
  type: string;
  text: string;
}

type ModalNotificationProps = {
  notifyUser: NotifyUser;
};

const ModalNotification = ({ notifyUser }: ModalNotificationProps) => {
  const { messages } = useContext(LanguageContext);
  return (
    <>
      {notifyUser.show ? (
        <Notification
          id={'modalNotification'}
          data-testid='modal-notification'
          className={`bg-${notifyUser.type}`}
        >
          {messages[notifyUser.text]}
        </Notification>
      ) : (
        <p className='p-1' />
      )}
    </>
  );
};

export default ModalNotification;
