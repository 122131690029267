import { isEmpty, remove } from 'lodash';
import { useState } from 'react';

import { ErrorsContext } from './Context';

export default function ErrorsProvider({ children }): JSX.Element {
  const [errors, setErrors] = useState<any>([]);

  const removeError = (s: string, f: string) => {
    const a = errors.slice();
    remove(a, ({ slug, field }) => {
      return slug === s && field === f;
    });
    setErrors(a);
  };

  const hasError = (s: string, f: string) => errors.find(e => e.slug === s && e.field === f);

  return (
    <ErrorsContext.Provider value={{ setErrors: setErrors, errors: errors, removeError: removeError, hasError: hasError }}>
      {children}
    </ErrorsContext.Provider>
  );
}
