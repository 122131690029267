import styled from 'styled-components';
import Color from 'color';
import '@fontsource/roboto';

export const Title = styled.div`
  display: flex;
  color: black;
  font-size: 20px;
  padding: 0.5em 0 2em 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  margin-bottom: -2.5em;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em 0 2em 0;
  color: #706d6d;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  margin-top: 2em;
  word-wrap: break-word;
`;

export const Body2 = styled.div`
  padding: 0.5em 0 2em 0;
  color: #706d6d;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmDialog = styled.div`
  padding: 2em;
  position: fixed;
  width: 738px;
  max-height: 820px;
  top: 50%;
  left: 50%;
  background-color: #ffffff;
  color: Black;
  z-index: 9999999;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  max-height: 95%;
`;

export const ConfirmDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  background-color: #000;
  opacity: 0.5;
`;

export const ConfirmBody = styled.div`
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
`;
