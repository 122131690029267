import Checkbox from '@material-ui/core/Checkbox';

type Props = {
  checked: boolean;
  handleChange: Function | any;
  inputProps?: Object;
  disabled?: boolean;
  size?: string | any;
  testId?: string;
};

export default function CheckboxComponent({
  checked,
  handleChange,
  inputProps = { 'aria-label': 'secondary checkbox' },
  disabled = false,
  size,
  testId,
}: Props) {
  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={inputProps}
      disabled={disabled}
      color='primary'
      size={size}
      data-testid={testId}
    />
  );
}
