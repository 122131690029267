import { ReactNode } from 'react';
import { Backdrop as MUIBackdrop, CircularProgress } from '@mui/material';

const Backdrop = ({
  loading = false,
  zIndex = 1301,
  children,
}: {
  loading?: boolean;
  children?: ReactNode;
  zIndex?: number;
}) => {
  return (
    <MUIBackdrop open={loading} sx={{ zIndex }}>
      {children || <CircularProgress color='inherit' sx={{ color: 'white' }} />}
    </MUIBackdrop>
  );
};

export default Backdrop;
