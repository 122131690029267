import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import type { Credentials, AuthResponse, Profile } from 'types';
import { inProgress } from '../../api';
import { resetSpoState } from 'features/SPOReport/redux';
import { resetUserState } from 'features/Administration/redux';
import { resetSurveyState } from 'features/Survey/redux';
import { resetOrganization } from 'features/OrganizationDetails/redux';
import { resetReportsExport } from 'features/Reports/Export/redux';
import { apiService } from 'api/apiService';
import type {
  ApiApplicationInformation,
  ApiAuthResponse,
  ApiSimpleProfileOut,
  GenericHttpResult,
} from '@agunity/api-v4';
import { TEMPLATE_NAME, Locale_English, TARGET, PERMISSIONS } from 'appConstants';
import { selectSelectedLanguages } from 'features/Localisation/redux';
import { differenceInMonths } from 'date-fns';

export const hot = '../features/login/redux.ts';

export interface LoginState {
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  response: AuthResponse;
  credentials: Credentials;
  profile: Profile;
  version: ApiApplicationInformation;
}

const initialState: LoginState = {
  loggedIn: false,
  status: 'idle',
  error: '',
  response: {} as AuthResponse,
  credentials: {} as Credentials,
  profile: {} as Profile,
  version: {},
};

export const counterSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginState: () => initialState,
    profile: (state, action) => {
      if (!!action.payload?.lastFeedbackAsOf) { // Added isNeedFeedback if the lastFeedbackAsOf date is equal or greater than 6 months
        const currentDate = new Date();
        const givenDate = new Date(action.payload.lastFeedbackAsOf);
        let monthsDifference = differenceInMonths(currentDate, givenDate);
        action.payload.isNeedFeedback = (monthsDifference >= 6);
      } else {
        action.payload.isNeedFeedback = true;
      }
      
      state.profile = action.payload;
    },
    login: (state, action) => {
      state.credentials = action.payload;
    },
    failure: (state, action) => {
      state.loggedIn = false;
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    },
    loggedIn: (state) => {
      state.loggedIn = true;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.credentials = {} as Credentials;
      state.profile = initialState.profile;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
  },
});

export const {
  login,
  failure,
  status,
  loggedIn,
  logout,
  profile,
  resetLoginState,
  setVersion,
} = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useAppSelector((state: RootState) => state.login.value)`
export const selectIsLoggedIn = (state: RootState) => state.login.loggedIn;

export const selectStatus = (state: RootState) => state.login.status;

export const selectError = (state: RootState) => state.login.error;

export const selectCredentials = (state: RootState) => state.login.credentials;

export const selectLoggedIn = (state: RootState) => state.login.loggedIn;

export const selectProfile = (state: RootState) => state.login.profile;

export const selectVersion = (state: RootState) => state.login.version;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const asyncLogin =
  (
    username: string,
    password: string,
    internalLogin?: {
      accessToken: string | null;
      headers: { Authorization: string };
    }
  ) =>
    async (dispatch: Function): Promise<GenericHttpResult<ApiAuthResponse>> => {
      await dispatch(resetSpoState());
      await dispatch(resetUserState());
      await dispatch(resetSurveyState());

      const response = internalLogin?.accessToken
        ? await apiService.authenticateV10InternalLogonCreate({ username, target: TARGET }, { headers: internalLogin.headers })
        : await apiService.authenticateV20WebCreate({ username, password, target: TARGET });
      const data: ApiAuthResponse = response.data;

      const expires = +new Date() + (data?.expiresIn || 0) * 1000;
      const creds: Credentials = { ...response.data, expires, token: data.securityToken };
      
      sessionStorage.setItem('auth', JSON.stringify(creds));
      await dispatch(login(creds));
      dispatch(expireCreds());

      return response;
    };

export const changePassword =
  (newPassword: string, token: string) =>
    async () => {
      const response = await apiService.authenticateV20ResetPasswordCreate({ token, newPassword });
      return response;
    };

export const updatePassword =
  (currentPassword: string, newPassword: string) =>
    async (): Promise<GenericHttpResult<void>> => {
      const response = apiService.authenticateV20ChangePasswordCreate({ currentPassword, newPassword });
      return response;
    };

export const validate =
  (emailAddress: string) =>
    async (dispatch: Function, getState: Function): Promise<GenericHttpResult<void>> => {
      const locale = selectSelectedLanguages(getState())?.locale || Locale_English;
      const response = await apiService.authenticateV22ValidateCreate({ locale, emailAddress, templateName: TEMPLATE_NAME, target: TARGET });
      return response;
    };

export const expireCreds =
  () =>
    async (dispatch: Function, getState: Function): Promise<void> => {
      const creds = selectCredentials(getState());
      if (+new Date() > creds.expires) dispatch(doLogout());
      else setTimeout(() => dispatch(expireCreds()), 1000 * 10);
    };

export const doLogout =
  () =>
    async (dispatch: Function): Promise<void> => {
      dispatch(resetSpoState());
      dispatch(resetUserState());
      dispatch(resetSurveyState());
      dispatch(resetOrganization());
      dispatch(resetReportsExport());
      // dispatch(resetLocalesState()); // workaround solution for 5334

      dispatch(logout());
      sessionStorage.setItem('auth', '');
      dispatch(inProgress(0));
      dispatch(failure(''));
      dispatch(status('idle'));
    };

export const fetchProfile =
  () =>
    async (
      dispatch: Function,
      getState: Function,
    ): Promise<GenericHttpResult<ApiSimpleProfileOut>> => {
      const response = await apiService.profileV20MainList();
      const userId = response?.data?.roles?.[0]?.userId;
      const perms = response?.data?.permissions || [];
      const spoData = getState()?.spo;
      const isPOUser = !perms?.some((id) => (id === PERMISSIONS.PN || id === PERMISSIONS.FI)); // Check if PO user
      const geolocationLink = (spoData?.organisation?.data?.find((info) => (/^GeoLink$/ig.test(info?.key) && !!info?.value)) || null);
      const profileData: Profile = selectProfile(getState());

      dispatch(profile({
        ...(profileData || {}),
        ...(response?.data || {}),
        userId,
        permissions: [
          ...(response?.data?.permissions || []),
          ...((isPOUser) ? [573] : []), // add 573 permission code for the PO users
          ...((isPOUser && geolocationLink) ? [1186] : []), // add 1186 permission code for the PO users that have GeoLink in there organisation data
          ...((geolocationLink) ? [1673] : []) // add 1673 permission code for the users that have GeoLink in there organisation data
        ]
      }));
      return response;
    };

export const getAPIVersion = () => {
  return async (
    dispatch: Function
  ): Promise<GenericHttpResult<ApiApplicationInformation> | void> => {
    const response = await apiService.onlineV11VersionList();
    if (response.status === 200) dispatch(setVersion(response.data));
    return response;
  };
};

export const init =
  () =>
    async (dispatch: Function): Promise<void> => {
      const data: string | null = sessionStorage.getItem('auth');
      if (data) {
        await dispatch(login(JSON.parse(data)));
        await dispatch(loggedIn());
        await dispatch(expireCreds());
        await dispatch(fetchProfile());
      }
    };

export default counterSlice.reducer;