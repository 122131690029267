import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiService } from 'api/apiService';

import apiReducer, { init as initApi, callEndpoint, hot as apiHot } from 'api';
import loginReducer, { hot as loginHot, init as initAuth } from 'features/login/redux';
import spoReducer, { hot as spoHot, init as initSPO } from 'features/SPOReport/redux';
import localesReducer, { hot as localesHot } from 'features/Localisation/redux';
import usersReducer, { hot as usersHot, init as usersInit } from 'features/Administration/redux';
import surveyReducer, { hot as surveyHot } from 'features/Survey/redux';
import commentsReducer, { hot as commentsHot } from 'components/Comments/redux';
import componentsReducer, { hot as componentsHot } from 'components/redux';
import organizationDetailsReducer, { hot as orgDetailsHot } from 'features/OrganizationDetails/redux';
import reportsExportReducer, { hot as reportsExportHot } from 'features/Reports/Export/redux';

const combined = combineReducers({
  login: loginReducer,
  api: apiReducer,
  spo: spoReducer,
  locales: localesReducer,
  users: usersReducer,
  survey: surveyReducer,
  comments: commentsReducer,
  components: componentsReducer,
  organization: organizationDetailsReducer,
  reportsExport: reportsExportReducer,
});

const middleware = () => {
  return (next) => {
    return (action) => {
      const credentials = action.payload?.login?.credentials;
      if (action.type === 'persist/REHYDRATE' && credentials) {
        // Set the token in the apiService
        apiService.securityToken = credentials.securityToken;
        apiService.userToken = credentials.userToken;
      }
      return next(action);
    };
  };
};

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, combined);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { callEndpoint },
      },
      serializableCheck: {
        ignoredActions: [ FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ] // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      }
    }).concat(middleware),
});

export const persistor = persistStore(store);

// add hot module replacement for reducers
if (process.env.NODE_ENV !== 'production' && module?.['hot']) {
  module['hot'].accept([
    loginHot,
    apiHot,
    spoHot,
    localesHot,
    usersHot,
    surveyHot,
    commentsHot,
    componentsHot,
    orgDetailsHot,
    reportsExportHot,
  ], () => store.replaceReducer(combined as any));
}

// run initialisers
(async () => {
  await store.dispatch(initAuth());
  await store.dispatch(initSPO());
  const p = [initApi, usersInit].map((init: any) => (store.dispatch(init())));
  await Promise.all(p);
})();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
