import {
  ApiBeneficiaries,
  ApiCategory,
  ApiCODImpact,
  ApiCODImpactExportImageRef,
  ApiCODImpactExportItem,
  ApiCODImpactOrgItem,
  ApiDocument,
  ApiDocumentPreview,
  ApiFTFullPlanResult,
  ApiFullPlan,
  ApiInvestmentPlanSummary,
  ApiItemRef,
  ApiItemTypes,
  ApiTranslateRequest,
  ApiTranslateResponse,
  ApiTranslationSetting,
  ApiSimpleOrgItem,
  GenericHttpResult,
  ApiWrapperStatus,
  ApiUnit,
} from '@agunity/api-v4';

import { apiService } from 'api/apiService';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

type SliceType = {
  orgsList: ApiSimpleOrgItem[];
  origOrganizationsSubsetFilter: { [key: string]: any };
  origCommercialProfilesSubsetFilter: { [key: string]: any };
  enOrganizationsSubsetFilter: { [key: string]: any };
  enCommercialProfilesSubsetFilter: { [key: string]: any };
};

export const hot = '../features/Reports/Export/redux.ts';

const initialState: SliceType = {
  orgsList: [],
  origOrganizationsSubsetFilter: {},
  origCommercialProfilesSubsetFilter: {},
  enOrganizationsSubsetFilter: {},
  enCommercialProfilesSubsetFilter: {}
};

export const storeSlice = createSlice({
  name: 'reportsExport',
  initialState,
  reducers: {
    resetReportsExport: () => (initialState),
    setOrgsList: (state, action) => { state.orgsList = action.payload; },
    setSubsetFilter: (state, action) => { state[action.payload.name] = action.payload.value; }
  }
});

export const { resetReportsExport, setOrgsList, setSubsetFilter } = storeSlice.actions;

export const getReportsExport = (state: RootState) => (state.reportsExport);

export const getOrgsList = (state: RootState) => (state.reportsExport.orgsList);

export const downloadDocument = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService.documentV10DownloadDetail(documentKey);
    return response;
  };
};

export const deleteFile = (documentKey: string) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.documentV10DeleteDelete(documentKey);
    return response;
  };
};

export const getDocumentList = (itemType: ApiItemTypes, itemId: number) => {
  return async (): Promise<GenericHttpResult<ApiDocument[]>> => {
    const response = await apiService.documentV10ListDetail(itemType, itemId);
    return response;
  };
};

export const fetchExportList = (property: string, settings: ApiTranslationSetting) => {
  return async (dispatch: Function, getState: () => RootState): Promise<GenericHttpResult<ApiCODImpactExportItem[]>> => {
    const { isActive, ...otherFilter } = getState()?.reportsExport?.[property] || {};
    const response = await apiService.fairtradeCodExportV11ListCreate({ settings, ...otherFilter });
    return response;
  };
};

export const fetchExportOrgList = (property: string) => {
  return async (dispatch: Function, getState: () => RootState): Promise<GenericHttpResult<ApiCODImpactOrgItem[]>> => {
    const { isActive, ...otherFilter } = getState()?.reportsExport?.[property] || {};
    const response = await apiService.fairtradeCodExportV10OrgListCreate(otherFilter || {});
    return response;
  };
};

export const deleteExport = (id: number) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.fairtradeCodExportV10ItemDelete(id);
    return response;
  };
};

export const publishExport = (data: { request?: string; file?: File }) => {
  return async (): Promise<GenericHttpResult<void>> => {
    const response = await apiService.fairtradeCodExportV10PublishCreate(data);
    return response;
  };
};

export const getCODImpactByYear = ({ orgId, year }: { orgId: number; year: number }) => {
  return async (): Promise<GenericHttpResult<ApiCODImpact[]>> => {
    const response = await apiService.fairtradeCodV10ItemDetail(orgId, year);
    return response;
  };
};

export const getInvestmentList = (orgId: number, status: ApiWrapperStatus) => {
  return async (): Promise<GenericHttpResult<ApiInvestmentPlanSummary[]>> => {
    const response = await apiService.fairtradePlanWrapperListSimpleV10InvestmentPlanForExportCreate(orgId, status);
    return response;
  };
};

export const getInvestmentResult = (wrapperId: number) => {
  return async (): Promise<GenericHttpResult<ApiFTFullPlanResult>> => {
    const response = await apiService.fairtradePlanResultV10ResultDetail(wrapperId);
    return response;
  };
};

export const getPlanItemDetails = (wrapperId: number) => {
  return async (): Promise<GenericHttpResult<ApiFullPlan>> => {
    const response = await apiService.planV10ItemDetail(wrapperId);
    return response;
  };
};

export const getCategories = () => {
  return async (): Promise<GenericHttpResult<ApiCategory[]>> => {
    const response = await apiService.fairtradeV11CategoriesList();
    return response;
  };
};

export const getBeneficiaries = () => {
  return async (): Promise<GenericHttpResult<ApiBeneficiaries[]>> => {
    const response = await apiService.planV11BeneficiariesList();
    return response;
  };
};

export const getPlanDocuments = (id: number) => {
  return async (): Promise<GenericHttpResult<ApiDocumentPreview[]>> => {
    const response = await apiService.planV10ItemDocumentsDetail(id);
    return response;
  };
};

export const getProductImage = (orgId: number, code: string) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService.fairtradeCodExportProductImageDetail(orgId, code);
    return response;
  };
};

export const getProductImageId = (orgId: number, code: string) => {
  return async (): Promise<GenericHttpResult<ApiItemRef>> => {
    const response = await apiService.fairtradeCodExportProductImageIdDetail(orgId, code);
    return response;
  };
};

export const getExportImageList = (exportId: number) => {
  return async (): Promise<GenericHttpResult<ApiCODImpactExportImageRef[]>> => {
    const response = await apiService?.fairtradeCodExportV10ItemImageListDetail(exportId);
    return response;
  };
};

export const getExportItem = (exportId: number) => {
  return async (): Promise<GenericHttpResult<ApiCODImpactExportItem>> => {
    const response = await apiService.fairtradeCodExportV10ItemDetail(exportId);
    return response;
  };
};

export const translateSet = (data: ApiTranslateRequest) => {
  return async (): Promise<GenericHttpResult<ApiTranslateResponse>> => {
    const response = await apiService?.translationsV10TranslateSetCreate(data);
    return response;
  };
};

export const getPNThumbnail = (orgId: number) => {
  return async (): Promise<GenericHttpResult<Blob>> => {
    const response = await apiService?.fairtradeV10ParentOrgThumbnailDetail(orgId);
    return response;
  };
};

export const fetchOrgList = () => (async (dispatch: Function) => {
  const response = await apiService.fairtradePlanWrapperListSimpleV10OrgForExportInvestmentList();
  if (response.status === 200) { dispatch(setOrgsList(response.data || [])); }
  return response;
});

export const fetchPlanList = ({ orgId, status }: { orgId: number, status: ApiWrapperStatus }) => (async () => {
  const response = await apiService.fairtradePlanWrapperListSimpleV10PlanWrapperDetail(orgId, status);
  response.data = response.data?.filter((details) => (details.status === ApiWrapperStatus.Plausible));
  return response;
});

export const fetchPlanDetails = (wrapperId: number) => (async () => {
  const response = await apiService.fairtradePlanWrapperV10ItemDetail(wrapperId);
  return response;
});

export const fetchInvestmentList = ({ orgId, status }: { orgId: number, status: ApiWrapperStatus }) => (async () => {
  const response = await apiService.fairtradePlanWrapperListSimpleV10InvestmentPlanForExportCreate(orgId, status);
  return response;
});

export const fetchInvestmentDetails = (data: number[]) => (async () => {
  const response = await apiService.planV10ItemListCreate(data);
  return response;
});

export const fetchCoverImageDetails = (orgId: number) => (async () => {
  const response = await apiService.fairtradeV10CoverImageDetail(orgId);
  return response;
});

export const fetchAreaUnitsListViaProductCode = (code: string) => async (): Promise<GenericHttpResult<ApiUnit[]>> => {
  const response = await apiService.fairtradeCodExportV10UnitListAreaDetail(code);
  return response;
};

export default storeSlice.reducer;