import { useState, useContext, ReactElement, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { doLogout, selectProfile } from 'features/login/redux';
import { selectOrganisation } from 'features/SPOReport/redux';
import { Row, Col } from 'react-bootstrap';
import Button from 'components/Button/Button';
import {
  AppBar,
  Badge,
  Divider,
  IconButton,
  MenuItem,
  Popover,
  Toolbar
} from '@material-ui/core';

import { LanguageContext } from 'components/Language/Context';
import { DirtyContext } from 'components/Dirty/Context';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import * as S from './Headbar.styled';
import LangSelect from 'components/Language/Selector';
import Modal from 'components/Modal';

import account_B from 'assets/icons/account_B.svg';
import account_G from 'assets/icons/account_G.svg';
import user from 'assets/icons/user.svg';
import _logout from 'assets/icons/_logout.svg';
import notification from 'assets/icons/bell.svg';
import helpDocsIcon from 'assets/icons/helpDocs.svg';
import helpSupportIcon from 'assets/icons/helpSupport.svg';
import feedbackIcon from 'assets/icons/feedback.svg';
import questionMark from 'assets/icons/help.svg';
import questionMarkActive from 'assets/icons/helpActive.svg';
import CurrencyDropdown from 'components/CurrencyDropdown/CurrencyDropdown';
import SurveySaveExitButton from '../../Survey/components/SurveySaveExitButton';
import SurveyHeadbar from '../../Survey/components/SurveyHeadbar';
import { selectFeatureFlags } from 'features/Administration/redux';
import { PERMISSIONS, FEATURE_FLAGS, ConsentState } from 'appConstants';
import FeedBackModal from './FeedbackModal';

type State = {
  search: string;
};

import type { FormType } from '../types';

const isMoreThan6Month = (dateString: string) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  givenDate.setMonth(givenDate.getMonth() + 6);
  return givenDate < currentDate;
};

function HeadBar({ handleToggle }) {
  const { locale } = useContext(LanguageContext);
  const [to, setTo] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const [toggeledState, setToggeledState] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const profile = useAppSelector(selectProfile);
  const organisation = useAppSelector(selectOrganisation);
  const { isDirty, setDirty } = useContext(DirtyContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  
  const featureFlags = useAppSelector(selectFeatureFlags);
  const hasSupportFeature = (featureFlags || []).includes(FEATURE_FLAGS[6]);

  const wrapperType = localStorage.getItem('selectedWrapperType');
  const wrapperYear = localStorage.getItem('selectedwrapperYear');
  const wrapperID = localStorage.getItem('selectedwrapperId');
  const cachedLangSelect = useMemo(() => <LangSelect />, []);
  const isExportList = useMemo(() => ['/export/lists', '/export/orgLists'].includes(location.pathname), [location.pathname]);

  // Only show the breadcrumb if the pathname contains at least three segments. 
  const pathname = location.pathname.split('/').filter(Boolean);
  const showBreadcrumb = pathname.length >= 3 && localStorage.getItem('selectedWrapperType') !== null;
  const isValidPath = pathname.length >= 3 && /^\/reportsList\/(draft|approved|past)\/.+/.test(location.pathname);
  const summaryStatus = isValidPath ? location.pathname.split('/')[2] : '';

  const userPermissions = useMemo(() => ({
    REPORT_AN_ISSUE: profile?.permissions?.some((code: number) => (code === PERMISSIONS.REPORT_AN_ISSUE))
  }), [profile]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const link = (to: string) => {
    handleMenuClose();
    history.push(to);
  };

  const logout = () => {
    dispatch(doLogout());
    handleMenuClose();
    setTimeout(() => history.push('/'), 100);
  };

  const doLink = (toLink: string, callback?: Function) => {
    if (isDirty) {
      setTo(toLink);
      setShowModal(true);
      return;
    }
    link(toLink);
    callback && callback();
  };
  
  const [feedbackForm, setFeedbackForm] = useState<FormType>({ show: false });
  const handleFeedback = () => { setFeedbackForm((currentState) => ({ ...currentState, show: true })); };
  const helpMenuId = 'help-menu';
  const renderHelpMenu = (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id={`${helpMenuId}-popover`}
        keepMounted
        open={isMenuOpen && anchorEl?.id === helpMenuId}
        onClose={handleMenuClose}
      >
        <div className='menu-wrapper' style={{ margin: '0.3em 0' }}>
          <MenuItem data-testid='user-guidance' style={{ paddingTop: '0.2em', paddingBottom: '0.2em' }} onClick={() => {
            window.open(`https://doc.agunity.com/fairinsight-${locale}`, '_blank');
            handleMenuClose();
          }}>
            <S.SVG_Icon src={helpDocsIcon} />
            &nbsp;&nbsp;
            <S.MenuItem className='action'>
              <FormattedMessage id='informationHub' />
            </S.MenuItem>
          </MenuItem>
          {hasSupportFeature && userPermissions.REPORT_AN_ISSUE && (
            <MenuItem data-testid='report-an-issue' style={{ paddingTop: '0.2em', paddingBottom: '0.2em' }} onClick={() => {
              window.open('https://form.asana.com/?k=CNLLrY0tXGME7R33L_tMpw&d=483532170256100', '_blank');
              handleMenuClose();
            }}>
              <S.SVG_Icon src={helpSupportIcon} />
              &nbsp;&nbsp;
              <S.MenuItem className='action'>
                <FormattedMessage id='reportAnIssue' />
              </S.MenuItem>
            </MenuItem>
          )}
          <MenuItem data-testid='user-guidance' style={{ paddingTop: '0.2em', paddingBottom: '0.2em' }} onClick={() => {
            handleFeedback();
            handleMenuClose();
          }}>
            <S.SVG_Icon src={feedbackIcon} />
            &nbsp;&nbsp;
            <S.MenuItem className='action'>
              <FormattedMessage id='feedback' />
            </S.MenuItem>
          </MenuItem>
        </div>
      </Popover>
      {feedbackForm.show && <FeedBackModal {...feedbackForm} onClose={() => { setFeedbackForm({ show: false, data: {} }); }} />}
    </>
  );
  useEffect(() => {
    const functionTimeout = setTimeout(() => {
      const filteredUserConsents = (profile?.consents || []).filter(({ givenConsent }) => (givenConsent === ConsentState.Blank)).map(item => ({ ...item, testType: 'user' }));
      const filteredOrgConsents = (organisation?.consents || []).filter(({ givenConsent, updatedAsOf }) => (givenConsent === ConsentState.Blank || (givenConsent === ConsentState.Rejected && isMoreThan6Month(updatedAsOf)))).map(item => ({ ...item, testType: 'org' }));;
      const notGivenConsent = [ ...filteredOrgConsents, ...filteredUserConsents ];
      if (notGivenConsent.length === 0 && profile.isNeedFeedback) { handleFeedback(); }
    }, 10);
    return () => { clearTimeout(functionTimeout); };
  }, [profile.consents, profile.isNeedFeedback, organisation]);

  const userMenuId = 'user-menu';
  const renderUserMenu = (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id={`${userMenuId}-popover`}
      keepMounted
      open={isMenuOpen && anchorEl?.id === userMenuId}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} style={{ marginTop: '0.5em' }}>
        <S.MenuItem>
          <FormattedMessage id='welcome' />, {profile?.firstName}{' '}
          {profile?.familyName}!
        </S.MenuItem>
      </MenuItem>
      <Divider
        variant='middle'
        style={{ marginTop: '0.3em', marginBottom: '0.3em' }}
      />
      <MenuItem data-testid='profile' onClick={() => doLink('/profile')}>
        <S.SVG_Icon src={user} />
        &nbsp;&nbsp;
        <S.MenuItem className='action'>
          <FormattedMessage id='profile' />
        </S.MenuItem>
      </MenuItem>
      <MenuItem data-testid='logout' onClick={() => logout()}>
        <S.SVG_Icon src={_logout} />
        &nbsp;&nbsp;
        <S.MenuItem className='action'>
          <FormattedMessage id='logout' />
        </S.MenuItem>
      </MenuItem>
      <MenuItem
        onClick={handleMenuClose}
        style={{
          marginTop: '-0.4em',
          marginLeft: '-0.4em',
          marginRight: '-0.4em'
        }}
      >
        {cachedLangSelect}
      </MenuItem>
    </Popover>
  );

  const title: ReactElement = (
    <S.ModalTitle>
      <FormattedMessage id='isDirty' />
    </S.ModalTitle>
  );

  const body: ReactElement = (
    <S.ModalBodyCol>
      <FormattedMessage id='dirtyConfirm' />
    </S.ModalBodyCol>
  );

  const footer: ReactElement = (
    <Row>
      <Col>
        <Button onClick={() => setShowModal(false)} variant='text'>
          <FormattedMessage id='cancel' />
        </Button>
      </Col>
      <Col>
        <Button
          onClick={() => {
            setDirty(false);
            setShowModal(false);
            link(to);
            localStorage.removeItem('selectedWrapperType');
            localStorage.removeItem('selectedWrapperYear');
          }}
        >
          <FormattedMessage id='ok' />
        </Button>
      </Col>
    </Row>
  );

  return (
    <S.Container $isToggled={toggeledState}>
      {showModal && (
        <Modal
          show={showModal}
          handleClose={() => setShowModal(false)}
          title={title}
          body={body}
          footer={footer}
          size='lg'
          style={{ width: '640px' }}
        />
      )}
      <S.Grow>
        <AppBar
          position='static'
          color='inherit'
          style={{ height: '3em', boxShadow: 'none' }}
        >
          <Toolbar style={{ minHeight: '3em' }}>
            {(showBreadcrumb || summaryStatus) && (
              <>
                <S.BlueText
                  onClick={() => {
                    doLink(
                      `/reportsList/${wrapperType || summaryStatus}`,
                      () => {
                        localStorage.removeItem('selectedWrapperType');
                        localStorage.removeItem('selectedWrapperYear');
                      }
                    );
                  }}
                >
                  {summaryStatus || wrapperType} <FormattedMessage id='plans' />
                </S.BlueText>

                {!summaryStatus ? (
                  <>
                    <S.Dot />
                    <S.BlueText
                      onClick={() => {
                        doLink(
                          `/reportsList/${wrapperType}/${wrapperID}`,
                          () => {
                            localStorage.removeItem('selectedWrapperType');
                            localStorage.removeItem('selectedWrapperYear');
                          }
                        );
                      }}
                      style={{ marginLeft: '2em' }}
                    >
                      {wrapperYear} Plan Summary
                    </S.BlueText>
                  </>
                ) : null}
              </>
            )}
            {isExportList && (
              <S.Link to='/export/commercialProfile' data-testid='export-link-header'>
                <FormattedMessage id='exports' />
              </S.Link>
            )}
            <SurveySaveExitButton />
            <S.Grow2 />
            <S.SectionDesktop>
              {location.pathname.toLowerCase().includes('reportslist/past') && (
                <CurrencyDropdown setSelectedCurrencyx={() => {}} />
              )}
              <IconButton
                aria-label='show 17 new notifications'
                color='inherit'
                onClick={() => {
                  doLink('/');
                }}
              >
                <Badge color='secondary'>
                  <S.SVG_Icon src={notification} />
                </Badge>
              </IconButton>

              <IconButton
                id={helpMenuId}
                aria-label='help documentation'
                aria-controls={helpMenuId}
                aria-haspopup='true'
                onClick={handleMenuOpen}
              >
                {(isMenuOpen && anchorEl?.id === helpMenuId) ? (
                  <S.SVG_Icon src={questionMarkActive} />
                ) : (
                  <S.SVG_Icon src={questionMark} />
                )}
              </IconButton>

              <IconButton
                data-testid={'headBar-userMenu-button'}
                id={userMenuId}
                edge='end'
                aria-label='account of current user'
                aria-controls={userMenuId}
                aria-haspopup='true'
                onClick={handleMenuOpen}
              >
                {((isMenuOpen && anchorEl?.id === userMenuId) || location.pathname.includes('/profile')) ? (
                  <S.SVG src={account_G} />
                ) : (
                  <S.SVG src={account_B} />
                )}
              </IconButton>
            </S.SectionDesktop>
          </Toolbar>
        </AppBar>
        {renderHelpMenu}
        {renderUserMenu}
      </S.Grow>
      <SurveyHeadbar />
    </S.Container>
  );
}

export default HeadBar;
