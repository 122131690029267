import styled from 'styled-components';

export const Button = styled.button<any>`
  text-transform: ${(props) => (props.$isPrimary ? 'none' : 'uppercase')};
  font-size: 0.9em;
  padding: 0.75em 1em;
  border: none;
  background: ${(props) =>
    !props.$noStatus && props.disabled
      ? 'grey'
      : props.$isPrimary
        ? '#3a7ef5'
        : 'var(--color-button)'};
  color: white;
  width: ${(props) => props.$width || '100%'};
  margin-top: ${(props) => (props.$noPadding ? '0' : '2em')};
  border-radius: 5px;
  cursor: pointer;
  :active {
    background: var(--color-button-active);
  }
`;

export const ButtonSave = styled.button<any>`
  margin-right: theme.spacing(1);
  float: right;
  background: ${(props) =>
    !props.$noStatus && props.disabled ? 'transparent' : 'transparent'};
  border-radius: 4px;
  min-width: 8em;
  height: 2.5em;
  color: ${(props) => (props.disabled ? 'grey' : '#2c3d52')};
  border: none !important;
  font-size: 0.8em;
  margin-right: 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const BlueButton = styled.button<any>`
  margin-right: theme.spacing(1);
  float: right;
  background-color: #2c3d52;
  border-radius: 4px;
  width: ${(props) => props.$width || 'auto'};
  min-width: ${({ $padding }) => !$padding && '6em'};
  height: ${({ $padding }) => !$padding && '2.5em'};
  color: white;
  border: 0;
  font-size: ${({ $fontSize }) => $fontSize || '0.8em'};
  cursor: pointer;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding: ${({ $padding }) => $padding && $padding};
  ${(props) =>
    !props.$noPadding &&
    `padding-right: 2em;
    padding-left: 2em;`}

  :hover {
    background-color: #3b5270;
  }

  :disabled {
    background-color: #9b9b9b;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled.button<any>`
  margin-right: theme.spacing(1);
  float: right;
  background-color: inherit;
  border-radius: 4px;
  min-width: ${({ $padding }) => !$padding && '6em'};
  ${(props) => (props.$width ? `width: ${props.$width};` : '')};
  height: 2.5em;
  color: #2c3d52;
  border: 0;
  font-size: ${({ $fontSize }) => $fontSize || '0.8em'};
  text-decoration: ${({ $underline }) => $underline && 'underline'};
  cursor: pointer;
  ${(props) =>
    !props.$noPadding &&
    `padding-right: 2em;
    padding-left: 2em;`}

  :hover {
    color: #2c3d52;
  }

  :disabled {
    color: #9b9b9b;
    cursor: not-allowed;
  }
  ${({ marginRight }) =>
    marginRight && `margin-right: ${marginRight}px !important;`};
`;

export const BoldText = styled.div<any>`
  ${({ boldText }) => boldText && 'font-weight: bold;'};
`;

export const BorderedButton = styled.button<any>`
  margin-right: theme.spacing(1);
  float: right;
  background-color: white;
  border-radius: 4px;
  min-width: 6em;
  height: 2.5em;
  color: #2d3d53;
  font-size: 0.8em;
  cursor: pointer;
  padding-right: 2em;
  padding-left: 2em;
  border: 2px solid #2c3d52;
  box-sizing: border-box;
  :disabled {
    color: darkgrey;
  }
`;
