import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
export const hot = '../Components/redux.ts';

type ComponentsState = {
  openCommentThread: any,
}

const initialState: ComponentsState = {
  openCommentThread: {},
};

export const componentsSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    resetComponentsState: () => initialState,
    openCommentThread: (state, action) => {
      state.openCommentThread[action.payload[0]] = action.payload[1];
    },
  }
});

export const { openCommentThread, resetComponentsState } = componentsSlice.actions;

export default componentsSlice.reducer;

export const selectOpenCommentThread =  (state: RootState) => state.components.openCommentThread;