import { useHistory, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { memo, useCallback } from 'react';
import backArrow from 'assets/icons/backarrow_darkBlue.svg';
import { SurveySaveExit_Container, SurveySaveExit_Text } from '../styled';

const SurveySaveExitButton = memo(() => {
  const location = useLocation();
  const history = useHistory();

  const handleSaveAndExit = useCallback(() => {
    history.push('/surveyList');
  }, [history]);

  if (!location.pathname.startsWith('/surveyItem/')) return null;

  return (
    <div style={SurveySaveExit_Container} onClick={handleSaveAndExit}>
      <img src={backArrow} alt='' />
      <div style={SurveySaveExit_Text}>
        <FormattedMessage id='saveDraftExit' />
      </div>
    </div>
  );
});

export default SurveySaveExitButton;
