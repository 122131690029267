import { store } from 'app/store';
import { isEmpty } from 'lodash';

import { Report } from 'types';
import { selectChildOrganisation, selectOrganisation } from './redux';
import { PRODUCER_SETUPS } from 'appConstants';

type IReturn = {
  slug: string;
  field: string;
};

const getOrgType = (orgId, orgType, childOrgs) => {
  if(orgType) return orgType;
  const org = (childOrgs || []).find(({ id }) => orgId === id);
  const producerSetup = (org?.data || []).find(({ key }) => key === 'ProducerSetup')?.value;
  const type = PRODUCER_SETUPS.find(({ id }) => producerSetup === id)?.orgType;
  return type;
};

export function ableToSave(report: Report): IReturn[] {
  const errors: any[] = [];
  if (isEmpty(report?.name?.trim()))
    errors.push({ slug: 'details', field: 'name' });
  return errors;
}

export function ableToSubmit(report: Report): IReturn[] {
  const org = selectOrganisation(store.getState());
  const childOrgs = selectChildOrganisation(store.getState());

  const orgType = getOrgType(report?.orgId || report?.proxyOrgId, org._type, childOrgs);
  const isHL = orgType === 'HL';
  const errors: any[] = [];
  // if (!report.majorCategoryId)
  //   errors.push({ slug: 'selectCategory', field: 'majorCategoryId' });
  // if (!report.minorCategoryId)
  //   errors.push({ slug: 'selectCategory', field: 'minorCategoryId' });
  // if (!report.subCategories || report.subCategories.length === 0)
  //   errors.push({ slug: 'selectCategory', field: 'subCategories' });
  if ((report.categories || [])?.length < 1) {
    errors.push({ slug: 'selectCategory', field: 'majorCategoryId' });
    errors.push({ slug: 'selectCategory', field: 'minorCategoryId' });
    errors.push({ slug: 'selectCategory', field: 'subCategories' });
  }
  if ((report.categories || [])?.length === 0)
    errors.push({ slug: 'selectCategory', field: 'subCategories' });
  if (isEmpty(report?.name?.trim()))
    errors.push({ slug: 'details', field: 'name' });
  if (isEmpty(report.description))
    errors.push({ slug: 'details', field: 'description' });
  if (isEmpty(report.expectedBenefits))
    errors.push({ slug: 'details', field: 'expectedBenefits' });
  if (isEmpty(report.initialProblems))
    errors.push({ slug: 'details', field: 'initialProblems' });
  if (isEmpty(report.additionalDetails) && isHL)
    errors.push({ slug: 'details', field: 'additionalDetails' });
  // if (isEmpty(report.budgetFT) && !(report.budgetFT >= 0))
  // errors.push({ slug: 'budget', field: 'budgetFT' });
  // if (isEmpty(report.budgetOther) && !(report.budgetOther >= 0)) errors.push({ slug: 'budget', field: 'budgetOther' });
  if (
    isEmpty(report?.beneficiaries) ||
    report?.beneficiaries?.length === 0 ||
    report.beneficiaries.filter((b) => b.totalNumber > 0).length === 0
  )
    errors.push({ slug: 'beneficiaries', field: 'beneficiaries' });
  if ((report?.budgetFT === 0 || !report?.budgetFT || isNaN(report?.budgetFT) || Number(report?.budgetFT) === 0)
    && isEmpty(report.reasonsForZeroBudget)) {
    errors.push({ slug: 'budget', field: 'reasonsForZeroBudgetNeeded' });
  }

  // todo decouple the validation of surveys and plans
  // validation for 'add survey instance' and 'edit survey instance'
  const pathname = window.location.pathname;
  // below the validation will be used in 2 scenarios, add instance when url startWith '/latestSurveys' and edit instance when url startWith '/latestSurveysEdit', to sum up, in both cases, pathname.startsWith('/latestSurveys')
  const isPath_Survey = pathname.startsWith('/latestSurveys');
  if (isPath_Survey && !report?.frequency)
    errors.push({ slug: 'details', field: 'frequency' });

  return errors;
}

export const DATE_CONSTRAINTS = {
  MIN_DATE: '1921-01-01',
  MAX_DATE: '2121-12-31'
};

//TODO: Setup Tests for this validator
/**
 * Meant to Validate a Date against Minimum and Maximum Data and also, ensure a correct date input
 * @param enteredDate
 * @param maxDate
 * @default '2121-12-31'
 * @param minDate
 * @default '1921-01-01'
 */
export const validateDate = (
  enteredDate: Date,
  maxDate = new Date(DATE_CONSTRAINTS.MAX_DATE),
  minDate = new Date(DATE_CONSTRAINTS.MIN_DATE)
): { valid: boolean; msg: '' | 'invalidDateFormat' | 'invalidDateRange' } => {
  if (isNaN(enteredDate.getTime())) {
    return {
      valid: false,
      msg: 'invalidDateFormat'
    };
  } else if (
    enteredDate.getTime() < minDate.getTime() ||
    enteredDate.getTime() > maxDate.getTime()
  ) {
    return {
      valid: false,
      msg: 'invalidDateRange'
    };
  } else {
    return {
      valid: true,
      msg: ''
    };
  }
};

type MIME_TYPES = {
  DOCUMENT: string[];
  PHOTO: string[];
};

/*
Refer to the following link
for common MIME types.
https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
export const ALLOWED_MIME_TYPES: MIME_TYPES = {
  DOCUMENT: [
    'application/pdf',
    'application/rtf',
    'text/rtf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  PHOTO: ['image/png', 'image/jpeg']
};

/**
 * Meant to Validate the MIME (File Type) Type of a file.
 * Usually used along with TextInput of type 'file'.
 */
export const validateFileMIME = (
  file: File,
  allowedTypes: Array<keyof MIME_TYPES>
) => {
  const fileType = file.type;
  for (let x = 0; x < allowedTypes.length; x++) {
    if (!ALLOWED_MIME_TYPES.hasOwnProperty(allowedTypes[x])) {
      throw new Error('Incorrect allowed-type');
    }
    if (ALLOWED_MIME_TYPES[allowedTypes[x]].includes(fileType)) {
      return true;
    }
  }
  return false;
};
