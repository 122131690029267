import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
export const hot = '../Comments/redux.ts';

type CommentsState = {
  showLongComments: boolean;
}

const initialState: CommentsState = {
  showLongComments: true,
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetCommentsState: () => initialState,
    showLongComments: (state, action) => {
      state.showLongComments = action.payload;
    }
  }
});

export const { showLongComments, resetCommentsState } = commentsSlice.actions;

export const selectShowLongComments = (state: RootState) => state.comments.showLongComments;

export default commentsSlice.reducer;