import React, { ReactText } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  makeStyles,
  SelectProps,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CSSProperties } from 'styled-components';

export interface IMuiDropdownProps extends SelectProps {
  options: { id: ReactText; value: ReactText; label?: ReactText }[];
  labelText?: ReactText;
  labelColor?: CSSProperties['color'];
  labelWeight?: CSSProperties['fontWeight'];
}

const MuiDropdown: React.FC<IMuiDropdownProps> = ({
  options,
  labelText,
  labelColor,
  labelWeight,
  className,
  ...rest
}) => {
  const SelectLabel = ({ label }) => {
    return (
      <span
        style={{
          color: labelColor || '#706D6D',
          fontSize: '14px',
          fontWeight: labelWeight || 400,
          marginRight: '1em',
        }}
      >
        {label}
      </span>
    );
  };
  
  const useStyles = makeStyles({
    container: {
      height: '32px',
    },
  
    root: {
      padding: '7px 12px',
      border: '1px solid #BEC7D8',
      borderRadius: '3px',
      '&:focus': {
        backgroundColor: '#FFF',
        borderRadius: '3px !important',
        border: '1px solid #C7D04A !important',
      },
      '&:hover': {
        borderRadius: '3px !important',
        border: '1px solid #C7D04A !important',
      },
    },
  
    labelIcon: {
      color: labelColor || '#5C5C5C',
      position: 'absolute',
      right: 6,
    },
  
    menuPaper: {
      boxShadow: 'none',
      border: '1px solid #BEC7D8',
      borderRaidus: 0,
    },
  
    menuList: {
      color: '#706D6D',
      fontWeight: 400,
      paddingTop: 0,
      paddingBottom: 0,
      background: 'white',
      '& li': {
        fontSize: '14px',
      },
      '& li.Mui-selected': {
        fontWeight: 500,
      },
    },
  });

  const classes = useStyles();
  return (
    <Select
      disableUnderline={true}
      displayEmpty={true}
      className={`${classes.container} ${className || ''}`}
      classes={{ root: classes.root }}
      renderValue={(value) => {
        return <SelectLabel label={labelText || value} />;
      }}
      {...rest}
      IconComponent={(props) => (
        <ExpandMoreIcon
          {...props}
          className={`${props.className} ${classes.labelIcon}`}
        />
      )}
      MenuProps={{
        classes: {
          paper: classes.menuPaper,
          list: classes.menuList,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
    >
      {options.map(({ id, value, label }, index) => (
        <MenuItem key={id} value={value}>
          {label || value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MuiDropdown;
