import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: static;
  height: 6em;
  padding: 1em 1em 2em 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1em solid #f6f7f7;
`;
export const RightButtons = styled.div`
  width: auto;
  position: relative;
  height: 5em;
  padding: 2em;
`;

export const Button = styled.button<any>`
  margin-right: theme.spacing(1);
  float: right;
  background-color: #0283f1;
  border-radius: 4px;
  min-width: 6em;
  height: 2.5em;
  color: white;
  border: 0;
  font-size: 0.8em;
  margin-top: 20px;
  cursor: pointer;
  padding-right: 2em;
  padding-left: 2em;
`;

export const ButtonBack = styled.button<any>`
  margin-right: theme.spacing(1);
  float: left;
  background-color: transparent;
  border-radius: 4px;
  min-width: 6em;
  height: 2.5em;
  color: #2c3d52;
  border: 2px solid #2c3d52;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
`;

export const ButtonBackDisabled = styled.button<any>`
  margin-right: theme.spacing(1);
  float: left;
  background-color: transparent;
  border-radius: 4px;
  min-width: 6em;
  height: 2.5em;
  color: #9b9b9b;
  border: 2px solid #9b9b9b;
  font-size: 0.8em;
  font-weight: bold;
  margin-top: 20px;
  :hover {
    cursor: not-allowed;
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: lightgray;
  margin: 1em 0;
`;

export const ArchiveTitle = styled.h4`
  color: #AF3827;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: -25px;
  margin-left: -3px;
  font-size: 1.4rem;
`;
